// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import LifecycleStatus from "models/LifecycleStatus";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import acknowledge from "../../../actions/acknowledge";
import adjustExpectedRange from "../../../actions/adjustExpectedRange";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import loadMechanical from "../actions/loadMechanical";
import removeMechanical from "../actions/removeMechanical";
import useMechanicalTab from "../hooks/useMechanicalTab";
import IMechanicalPoles from "../models/IMechanicalPoles";
import getMechanicalSelector from "../selectors/getMechanicalSelector";
import Mechanical from "./Mechanical";

interface IMechanicalTabActions {
  adjustExpectedRange: (
    intl: IntlShape,
    notifications: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  acknowledge: (
    intl: IntlShape,
    notifications: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  loadMechanical: () => void;
  removeMechanical: () => void;
}

interface IMechanicalTabState {
  mechanical: Data<IMechanicalPoles>;
}

interface IMechanicalTabOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
}

interface IMechanicalTabProps
  extends IMechanicalTabActions,
    IMechanicalTabState,
    IMechanicalTabOwnProps {}

const MechanicalTab = ({
  adjustExpectedRange,
  acknowledge,
  assetId,
  loadMechanical,
  mechanical,
  removeMechanical,
  lifecycleStatus
}: IMechanicalTabProps) => {
  const {
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    mechanicalStatus,
    mechanicalData
  } = useMechanicalTab(
    assetId,
    mechanical,
    adjustExpectedRange,
    acknowledge,
    loadMechanical,
    removeMechanical
  );
  return (
    <>
      {mechanicalStatus === Statuses.Loading && <Processing />}
      {mechanicalStatus === Statuses.Succeeded && mechanicalData && (
        <Mechanical
          mechanical={mechanicalData}
          lifecycleStatus={lifecycleStatus}
          onAdjustExpectedRangeClick={handleAdjustExpectedRangeClick}
          onAcknowledgeClick={handleAcknowledgeClick}
        />
      )}
      {mechanicalStatus === Statuses.NotFound && <WidgetNoDataMessage />}
      {mechanicalStatus === Statuses.Failed && <WidgetErrorMessage />}
    </>
  );
};

export default connect(
  (
    state: IState,
    { assetId }: IMechanicalTabOwnProps
  ): IMechanicalTabState => ({
    mechanical: getMechanicalSelector(state)(assetId)
  }),
  (
    dispatch: AppDispatch,
    { assetId }: IMechanicalTabOwnProps
  ): IMechanicalTabActions => ({
    adjustExpectedRange: (
      intl: IntlShape,
      notifications: INotifications,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) =>
      adjustExpectedRange({
        intl,
        notifications,
        assetId,
        parameterName,
        success,
        error
      }),
    acknowledge: (
      intl: IntlShape,
      notifications: INotifications,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) =>
      acknowledge({
        intl,
        notifications,
        assetId,
        parameterName,
        success,
        error
      }),
    loadMechanical: () => dispatch(loadMechanical(assetId)),
    removeMechanical: () => dispatch(removeMechanical(assetId))
  })
)(MechanicalTab);
