// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography
} from "antd";
import { formatDateForDatePicker } from "common/DateTime/utils/dateFormatters";
import { Dayjs } from "dayjs";
import update from "immutability-helper";
import { isValidElement, useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import IField from "../../../models/IField";
import boolValues from "../constants/boolValues";
import AssetNameplateContext from "../contexts/AssetModalContext";
import disableFutureDates from "../utils/disableFutureDates";
import isSelectOption from "../utils/isSelectOption";

const { Text } = Typography;
const { Option } = Select;

interface ICustomFieldProps {
  configuration: IField;
  editAction?: () => void;
  editActionDisabled?: boolean;
  fieldIsRequired?: (fieldName: string) => boolean;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Dayjs;
  className?: string;
}

const CustomField = ({
  configuration,
  editAction,
  editActionDisabled = false,
  fieldIsRequired,
  getInitialValue,
  className
}: ICustomFieldProps) => {
  const intl = useIntl();

  const { isEditMode, isReadOnlyMode } = useContext(AssetNameplateContext);

  const validationRules = useMemo(() => {
    if (fieldIsRequired && fieldIsRequired(configuration.fieldKey)) {
      return update(configuration.rules ?? [], { $push: [{ required: true }] });
    } else {
      return configuration.rules;
    }
  }, [configuration.fieldKey, configuration.rules, fieldIsRequired]);

  const fieldName = useMemo(
    () =>
      configuration?.guid
        ? `${configuration.fieldKey}-${configuration.guid}`
        : configuration.fieldKey,
    [configuration?.guid, configuration?.fieldKey]
  );

  const disableField = useMemo(() => {
    return (
      configuration.disabled ||
      (isEditMode && configuration.disabledInEditMode) ||
      (isReadOnlyMode && configuration.fieldKey !== "Status")
    );
  }, [
    configuration.disabled,
    configuration.disabledInEditMode,
    configuration.fieldKey,
    isEditMode,
    isReadOnlyMode
  ]);

  const initialValue = getInitialValue
    ? getInitialValue(configuration?.fieldKey)
    : null;

  return (
    <div className={`${className} form-field`}>
      <Form.Item
        className={configuration.unit ? "with-unit" : ""}
        label={intl.formatMessage({ ...configuration.fieldName })}
        tooltip={
          configuration.tooltip
            ? intl.formatMessage({ ...configuration.tooltip })
            : undefined
        }
      >
        {editAction && (
          <div className="field-actions">
            <Icon
              name="border_color"
              onClick={editAction}
              variant="outlined"
              disabled={editActionDisabled}
            />
          </div>
        )}
        {configuration.inputType === "autoComplete" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <AutoComplete
              disabled={disableField}
              allowClear={true}
              showSearch={true}
              options={configuration.autoCompleteOptions || []}
              filterOption={(input, option) =>
                option.value
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}

        {configuration.inputType === "list" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <Select
              showSearch={true}
              disabled={disableField}
              allowClear={true}
              filterOption={(input, option) => {
                const optionValue =
                  option.label && !isValidElement(option.label)
                    ? option.label
                    : option.value;

                return optionValue
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {isSelectOption(configuration.listValues)
                ? configuration.listValues.map(({ value, label }) => {
                    return (
                      <Option key={value} value={value} label={label}>
                        {label}
                      </Option>
                    );
                  })
                : configuration.listValues.map((value) => (
                    <Option
                      key={value.toString()}
                      value={
                        typeof value === "boolean" ? value.toString() : value
                      }
                    >
                      {value.toString()}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        )}
        {configuration.inputType === "Decimal" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <InputNumber disabled={disableField} />
          </Form.Item>
        )}
        {configuration.inputType === "DateTime" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <DatePicker
              disabledDate={
                configuration.disableFutureDates ? disableFutureDates : null
              }
              disabled={disableField}
              format={formatDateForDatePicker(intl)}
            />
          </Form.Item>
        )}
        {configuration.inputType === "Bool" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <Select showSearch={true} disabled={disableField}>
              {boolValues.map((value) => (
                <Option key={value.toString()} value={value.toString()}>
                  {value.toString()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {configuration.inputType === "String" && (
          <Form.Item
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <Input disabled={disableField} />
          </Form.Item>
        )}
        {configuration.unit && (
          <Text type="secondary" className="unit">
            {configuration.unit}
          </Text>
        )}
      </Form.Item>
    </div>
  );
};

export default styled(CustomField)`
  .ant-input-number {
    width: 100%;
  }

  &.form-field {
    .ant-col {
      overflow: hidden;
    }
  }
`;
