// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import styled from "styled-components";
import {
  colorTeal80,
  colorWidgetBackground,
  colorWidgetBorder
} from "styles/ColorVariables";
import {
  fontSizeSmall,
  spacingLarge,
  spacingSmall,
  spacingXLarge,
  spacingXSmall,
  spacingXXLarge
} from "styles/StyleVariables";

interface IAssetModalFormsProps {
  className?: string;
  children: JSX.Element;
}

const AssetModalForms = ({ children, className }: IAssetModalFormsProps) => {
  return <div className={className}>{children}</div>;
};

const inputHeight = "32px";
const labelMinHeight = "42px";

const StyledAssetModalForms = styled(AssetModalForms)`
  height: 100%;
  margin: 0;
  overflow: auto;

  .section {
    margin-bottom: ${spacingXLarge};
    border: 1px solid;
    border-color: ${colorWidgetBorder};
    background-color: ${colorWidgetBackground};
  }

  .section-list {
    padding: ${spacingXXLarge} ${spacingXXLarge} 0px ${spacingXXLarge};
  }

  .section-nodes-horizontally {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-label {
      flex-basis: 50%;
      text-transform: uppercase;

      label {
        width: 100%;
        overflow-wrap: break-word;
        white-space: break-spaces;
        text-align: left;
        height: auto;
        min-height: ${labelMinHeight};
        margin-bottom: ${spacingLarge};
        align-items: flex-start;
      }
    }

    .ant-form-item-control {
      flex-basis: 50%;

      .ant-input-number,
      .ant-picker {
        width: 100%;
      }
    }

    .ant-form-item-explain {
      min-height: auto;
      .ant-form-item-explain-error {
        line-height: normal;
      }
    }

    .ant-input-group-addon {
      padding: 0px ${spacingXSmall};
    }

    &.date-of-inspection {
      margin-left: ${spacingLarge};
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .ant-form-item-label {
        text-transform: uppercase;
        flex: none;
        margin-right: ${spacingXLarge};

        label {
          min-height: ${inputHeight};
          margin-bottom: ${spacingXXLarge};
          align-items: center;
        }
      }

      .ant-form-item-control {
        flex: none;
      }
    }
  }

  .ant-form-item + button {
    margin-bottom: ${spacingLarge};
  }

  .add-to-list {
    padding: ${spacingSmall} 0px;
    margin-bottom: ${spacingLarge};
    color: ${colorTeal80};
  }

  .form-field {
    &:not(:first-of-type) {
      border-top: 1px solid ${colorWidgetBorder};
      padding-top: ${spacingXLarge};
    }

    .ant-form-item {
      &.with-unit {
        .ant-form-item-explain {
          padding-right: ${spacingXXLarge};
        }
      }

      .unit {
        font-size: ${fontSizeSmall};
        position: absolute;
        top: ${inputHeight};
        right: ${spacingXSmall};
      }
    }
  }
`;

export default StyledAssetModalForms;
