// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Popover, Row } from "antd";
import { ReplacementScoreTooltip } from "common/ReplacementScore";
import ReplacementScoreFormatter from "common/formatters/ReplacementScore";
import { isNil } from "lodash";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import AssetRibbonContext from "../contexts/AssetRibbonContext";
import Label from "./Label";
import NumberValue from "./NumberValue";
import "./ReplacementScore.less";
import ReplacementScoreFamilyIcon from "./ReplacementScoreFamilyIcon";

interface IReplacementScoreContextProps {
  assetId: string;
}

interface IReplacementScoreOwnProps {
  rank: number;
  total: number;
  replacementScore: number;
  isWarning: boolean;
  isInFamily: boolean;
  assetType: string;
  lifecycleStatus: string;
}

type ReplacementScoreProps = IReplacementScoreContextProps &
  IReplacementScoreOwnProps;

const ReplacementScore = memo(
  ({
    rank,
    total,
    isWarning,
    assetType,
    replacementScore,
    assetId,
    isInFamily,
    lifecycleStatus
  }: ReplacementScoreProps) => {
    const showRank = !isNil(rank) && !isNil(total);

    const replacementScoreParentColumnRef = useRef(null);
    const replacementScoreRef = useRef(null);
    const [replacementScoreSmallerClass, setReplacementScoreSmallerClass] =
      useState("");
    const checkReplacementScoreCount = useCallback(() => {
      const paddings = 20;
      if (
        replacementScoreParentColumnRef?.current?.clientWidth - paddings <
        replacementScoreRef?.current?.clientWidth
      ) {
        setReplacementScoreSmallerClass("smaller");
      } else setReplacementScoreSmallerClass("");
    }, []);

    useEffect(() => {
      checkReplacementScoreCount();
    }, [checkReplacementScoreCount]);

    return (
      <div className="asset-detail-replacement-score asset-ribbon-section">
        <div
          className="asset-ribbon-column"
          ref={replacementScoreParentColumnRef}
        >
          <div data-qa="header-replacement-label">
            <Row justify="space-between">
              <Col>
                <Label>
                  <FormattedMessage
                    id="asset_detail_page.header.replacement_score"
                    defaultMessage="Replacement Rank"
                  />
                </Label>
              </Col>
              <Col style={{ position: "absolute", right: 10 }}>
                {isInFamily && lifecycleStatus === "InService" && (
                  <ReplacementScoreFamilyIcon assetId={assetId} />
                )}
              </Col>
            </Row>
          </div>
          <div>
            {showRank ? (
              <Popover
                content={
                  <ReplacementScoreTooltip
                    assetType={assetType}
                    rank={rank}
                    scoreValue={replacementScore}
                    total={total}
                  />
                }
              >
                <div data-qa="header-replacement-value">
                  <Row className="asset-detail-replacement-score-grid-row">
                    <Col
                      className={`replacement-score ${replacementScoreSmallerClass}`}
                      ref={replacementScoreRef}
                    >
                      <NumberValue className={`${isWarning ? "warning" : ""}`}>
                        #<FormattedNumber value={rank} />
                      </NumberValue>
                      <NumberValue>
                        <span className="separator">/</span>
                        <FormattedNumber value={total} />
                      </NumberValue>
                    </Col>
                  </Row>
                </div>
              </Popover>
            ) : (
              <NumberValue>
                <ReplacementScoreFormatter value={replacementScore} />
              </NumberValue>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default (props: IReplacementScoreOwnProps) => {
  const { assetId } = useContext(AssetRibbonContext);
  return <ReplacementScore {...props} assetId={assetId} />;
};
