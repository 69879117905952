// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Button } from "antd";
import { ButtonType } from "antd/lib/button/index";
import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import ExportModal from "./ExportModal";

interface IExportButton {
  type?: ButtonType;
  disabled?: boolean;
  modelId: string;
  implementationId: string;
}

const ExportButton = ({
  type,
  disabled,
  modelId,
  implementationId
}: IExportButton) => {
  const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => setExportModalVisible(true)}
        type={type}
      >
        <FormattedMessage
          id="data_integration.model_config.export"
          defaultMessage="Export"
        />
      </Button>
      <ExportModal
        visible={exportModalVisible}
        closeExportModal={() => setExportModalVisible(false)}
        implementationId={implementationId}
        modelId={modelId}
      />
    </>
  );
};

export default memo(ExportButton);
