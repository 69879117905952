// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";

const DuvalTriangleType4Note = () => {
  return (
    <div className="duval-triangle-note">
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type5.text1"
          defaultMessage="The Duval triangle 5 for thermal faults in mineral oils uses gases CH₄, C₂H₄ and C₂H₆."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type5.text2"
          defaultMessage="The Duval Triangle 5 is used to have more information about faults identified as thermal faults (T2 or T3) with the Duval Triangle 1 for mineral oils (using CH₄, C₂H₄ and C₂H₂)."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type5.text3"
          defaultMessage="Do not use the Duval Triangle 5 for faults D1, D2."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type5.text4"
          defaultMessage="If Triangle 5 and Triangle 4 do not agree, this probably indicates a mixture of faults."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type5.text5"
          defaultMessage="Faults appearing in zone C have of probability of involving carbonization of paper of 80% (not 100%)."
        />
      </span>
    </div>
  );
};

export default DuvalTriangleType4Note;
