// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import EFluidType from "features/detailpage/components/analytics/DuvalTriangle/models/EFluidType";
import EGasStandard from "features/detailpage/components/analytics/DuvalTriangle/models/EGasStandard";
import ERevision from "features/detailpage/components/analytics/DuvalTriangle/models/ERevision";
import IDuvalTabConfiguration from "features/detailpage/components/analytics/DuvalTriangle/models/IDuvalTabConfiguration";
import getIsKinectricsTransformerMineralOilModel from "features/detailpage/utils/getIsKinectricsTransformerMineralOilModel";
import getIsKinectricsTransformerSiliconeModel from "features/detailpage/utils/getIsKinectricsTransformerSiliconeModel";
import getIsTransformerStandardModelEsters from "features/detailpage/utils/getIsTransformerStandardModelEsters";
import getIsTransformerStandardModelMinerals from "features/detailpage/utils/getIsTransformerStandardModelMineral";
import getIsTransformerStandardModelSilicone from "features/detailpage/utils/getIsTransformerStandardModelSilicone";
import type1Default from "../constants/type1Default";
import type3Default from "../constants/type3Default";
import type3_SeedDefault from "../constants/type3_SeedDefault";
import type3_SiliconeDefault from "../constants/type3_SiliconeDefault";
import type3_SoyDefault from "../constants/type3_SoyDefault";
import type3_SyntheticDefault from "../constants/type3_SyntheticDefault";
import type4Default from "../constants/type4Default";
import type5Default from "../constants/type5Default";

const isSyntheticFluidType = (fluidType: EFluidType) =>
  [EFluidType.MIDEL_7131, EFluidType.OTHER_SYNTHETIC_ESTER].includes(fluidType);

const isSoyBasedFluidType = (fluidType: EFluidType) =>
  [
    EFluidType.FR3,
    EFluidType.MIDEL_EN_1215,
    EFluidType.OTHER_NATURAL_ESTER_SOY
  ].includes(fluidType);

const isSeedBasedFluidType = (fluidType: EFluidType) =>
  [
    EFluidType.BIOTEMP,
    EFluidType.MIDEL_EN_1204,
    EFluidType.OTHER_NATURAL_ESTER_SEED
  ].includes(fluidType);

const getDuvalTabs = (
  implementationId: string,
  gasStandard: EGasStandard,
  revision: ERevision,
  fluidType: EFluidType
) => {
  let showPentagons = false;
  let isDuvalType3HasNote = false;

  const isKinectricsSiliconeModel = getIsKinectricsTransformerSiliconeModel(
    implementationId.toLowerCase()
  );

  const isKinectricsMineralModel = getIsKinectricsTransformerMineralOilModel(
    implementationId.toLowerCase()
  );

  const isTransformerStandardModelMineral =
    getIsTransformerStandardModelMinerals(
      implementationId.toLowerCase(),
      gasStandard
    );

  const isTransformerStandardModelEsters = getIsTransformerStandardModelEsters(
    implementationId.toLowerCase(),
    gasStandard,
    revision
  );

  const isTransformerStandardModelSilicone =
    getIsTransformerStandardModelSilicone(
      implementationId.toLowerCase(),
      gasStandard,
      revision
    );

  const duvalConfiguration: IDuvalTabConfiguration = (() => {
    switch (true) {
      case isKinectricsSiliconeModel:
        return {
          type1: [],
          type3: type3Default,
          type4: [],
          type5: []
        };
      case isKinectricsMineralModel:
        return {
          type1: type1Default,
          type3: [],
          type4: type4Default,
          type5: []
        };
      case isTransformerStandardModelMineral:
        showPentagons = true;
        return {
          type1: type1Default,
          type3: [],
          type4: type4Default,
          type5: type5Default
        };
      case isTransformerStandardModelSilicone:
        isDuvalType3HasNote = true;
        return {
          type1: [],
          type3: type3_SiliconeDefault,
          type4: [],
          type5: []
        };
      case isTransformerStandardModelEsters &&
        isSyntheticFluidType(fluidType): {
        isDuvalType3HasNote = true;
        return {
          type1: [],
          type3: type3_SyntheticDefault,
          type4: [],
          type5: []
        };
      }
      case isTransformerStandardModelEsters && isSoyBasedFluidType(fluidType): {
        isDuvalType3HasNote = true;
        return {
          type1: [],
          type3: type3_SoyDefault,
          type4: [],
          type5: []
        };
      }
      case isTransformerStandardModelEsters &&
        isSeedBasedFluidType(fluidType): {
        isDuvalType3HasNote = true;
        return {
          type1: [],
          type3: type3_SeedDefault,
          type4: [],
          type5: []
        };
      }

      default:
        return {
          type1: [],
          type3: [],
          type4: [],
          type5: []
        };
    }
  })();

  return { duvalConfiguration, isDuvalType3HasNote, showPentagons };
};

export default getDuvalTabs;
