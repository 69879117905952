// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IssueCompactContainer from "features/detailpage/features/issues/containers/IssueCompactContainer";
import IssueDetailsContainer from "features/detailpage/features/issues/containers/IssueDetailsContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";

import { useEffect, useRef } from "react";
import "./Issue.less";

interface IIssueProps {
  currentIssueId: string;
  issue: IIssue;
  meta: IIssueMeta;
  urgencies: string[];
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  readonly?: boolean;
  isComponentLevel?: boolean;
  onSaved?: (issueId: string) => void;
  onStatusSaved?: (issueId: string) => void;
  scrollToElement?: (element: HTMLElement) => void;
}

const Issue = (props: IIssueProps) => {
  const {
    currentIssueId,
    disabled,
    issue,
    meta,
    readonly,
    urgencies,
    isComponentLevel,
    scrollToElement,
    onSaved,
    onClick,
    onStatusSaved
  } = props;

  const isCurrent = issue.Id === currentIssueId;
  const refEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCurrent && scrollToElement && refEl.current) {
      scrollToElement(refEl.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={refEl}
      className={`
          issue
          ${disabled ? "disabled" : ""}
          ${isCurrent ? "active" : ""}
        `}
      onClick={(e) => !disabled && onClick?.(e)}
    >
      {!isCurrent && (
        <IssueCompactContainer issue={issue} meta={meta} readonly={readonly} />
      )}
      {isCurrent && (
        <IssueDetailsContainer
          issue={issue}
          meta={meta}
          readonly={readonly}
          urgencies={urgencies}
          isComponentLevel={isComponentLevel}
          onSaved={() => onSaved?.(issue.Id)}
          onStatusSaved={() => onStatusSaved?.(issue.Id)}
        />
      )}
      {disabled && <div className="overlay" />}
    </div>
  );
};

export default Issue;
