// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import { Button } from "antd";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IColumn } from "./DataGrid";

interface IExportToCsvButtonProps {
  onClick?: (columns: IColumn[], notifications: INotifications) => void;
  disabled?: boolean;
  columns?: IColumn[];
  notifications?: INotifications;
}

const ExportToCsvButton = ({
  onClick,
  disabled,
  columns,
  notifications
}: IExportToCsvButtonProps) => {
  const handleExportCsvClick = useCallback(() => {
    onClick?.(columns, notifications);
  }, [columns, notifications, onClick]);

  return (
    <Button
      className="light"
      disabled={disabled}
      type="link"
      onClick={handleExportCsvClick}
      data-qa="export-csv-button"
    >
      <FormattedMessage defaultMessage="Csv" id="data_grid.footer.export.csv" />
    </Button>
  );
};

export default ExportToCsvButton;
