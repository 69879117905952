// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import INotifications from "@pg/common/build/components/Notifications/models/INotifications";
import NotificationsService from "core/app/services/NotificationsService";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";
import { RogersRatioData } from "../models/RogersRatioData";

export enum Actions {
  RogersRatio_SetRogersRatioData = "RogersRatio_SetRogersRatioData"
}

export type RogersRatioActions = IAjaxAction<RogersRatioData>;

export const setRogersRatioChartAction = (
  request: JQueryXHR,
  data?: RogersRatioData
): IAjaxAction<RogersRatioData> => ({
  type: Actions.RogersRatio_SetRogersRatioData,
  request,
  data
});

export const loadRogersRatioChartData = (
  dispatch: AppDispatch,
  intl: IntlShape,
  notifications: INotifications,
  assetId?: string
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.rogersRatioUrl, [
      {
        name: "assetId",
        value: assetId
      }
    ]);
    const request = EndpointService.getJson<RogersRatioData>(
      url,
      (request, data) => {
        dispatch(setRogersRatioChartAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setRogersRatioChartAction(request));
        NotificationsService.notifyIfRequestFailed(
          intl,
          notifications,
          request,
          {
            id: "rogers_ratio.data.loading_failed",
            defaultMessage:
              "Cannot load rogers ratio data. Response code: {code}"
          }
        );
        reject();
      }
    );

    dispatch(setRogersRatioChartAction(request));
  });
};
