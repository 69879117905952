// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import { useContext } from "react";

const withNotifications = (Component: any) => {
  return function EnhancedComponent(props: any) {
    const { notifications } = useContext(NotificationContext);

    return <Component {...props} notifications={notifications} />;
  };
};

export default withNotifications;
