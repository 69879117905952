// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import AssetRibbon from "features/detailpage/features/AssetRibbon";
import IssuesContainer from "features/detailpage/features/issues/containers/IssuesContainer";
import { PageModes } from "features/detailpage/models/PagesModes";
import DetailPageWidgetContextProvider from "features/detailpage/providers/DetailPageWidgetContextProvider";
import isKinectricsNetworkTransformerModel from "features/detailpage/utils/getIsKinectricsTransformerModel";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { IntlShape, injectIntl } from "react-intl";
import IAssetDetails from "../../../models/IAssetDetails";
import usePageMode from "../hooks/usePageMode";
import AssetImplementationId from "../models/AssetImplementationId";
import ConditionMonitoringType from "../models/ConditionMonitoringType";
import getLeftRightPanelClassName from "../utils/getLeftRightPanelClassName";
import DetailPageWidgets from "./DetailPageWidgets";
import TitlePanel from "./TitlePanel";

interface IDetailPageContentContextProps {
  isParametersListWidgetVisible: boolean;
}

export interface IDetailPageContentOwnProps {
  assetDetails: IAssetDetails;
  allowToCollapse: boolean;
  assetId: string;
  issueId: string;
  sidePanelExpanded: boolean;
  rightPanelExpanded: boolean;
  expandRightPanel: () => void;
  collapseRightPanel: () => void;
}

export interface IDetailPageContentState {
  pageMode: PageModes;
}

type DetailPageContentProps = IDetailPageContentContextProps &
  IDetailPageContentOwnProps & {
    intl: IntlShape;
  };

const DetailPageContent = (props: DetailPageContentProps) => {
  const {
    assetId,
    issueId,
    intl,
    assetDetails,
    allowToCollapse,
    sidePanelExpanded,
    rightPanelExpanded,
    expandRightPanel,
    collapseRightPanel,
    isParametersListWidgetVisible
  } = props;

  const issueRef = useRef(null);

  const { pageMode } = usePageMode(
    issueId,
    isParametersListWidgetVisible,
    rightPanelExpanded,
    collapseRightPanel
  );

  const getAssetType = useCallback(() => {
    let assetType = assetDetails?.NameplateWithModelInfo?.AssetType || null;

    if (
      assetType === undefined ||
      (assetType != null && assetType.trim() === "")
    ) {
      assetType = null;
    }

    return assetType;
  }, [assetDetails?.NameplateWithModelInfo?.AssetType]);

  const getParentId = useCallback(() => {
    return assetDetails?.NameplateWithModelInfo?.ParentId || null;
  }, [assetDetails]);

  const isChartWidgetsExpanded = useCallback(() => {
    return (
      (pageMode === PageModes.Left3Right1 &&
        !sidePanelExpanded &&
        !rightPanelExpanded) ||
      getParentId() !== null
    );
  }, [pageMode, sidePanelExpanded, rightPanelExpanded, getParentId]);

  const getModelImplementationId = useCallback(() => {
    return assetDetails?.NameplateWithModelInfo?.ModelImplementationId || null;
  }, [assetDetails?.NameplateWithModelInfo?.ModelImplementationId]);

  const getConditionMonitoringType = useCallback(() => {
    const conditionMonitoringAttributeName = "Condition Monitoring Type";

    return (
      assetDetails?.NameplateWithModelInfo?.Attributes[
        conditionMonitoringAttributeName
      ] || null
    );
  }, [assetDetails?.NameplateWithModelInfo?.Attributes]);

  const getLifecycleStatus = useCallback(() => {
    return assetDetails?.NameplateWithModelInfo.LifecycleStatus || null;
  }, [assetDetails?.NameplateWithModelInfo.LifecycleStatus]);

  const getReplacementScoreModelImplementationId = useCallback(() => {
    return (
      assetDetails?.NameplateWithModelInfo
        ?.ReplacementScoreModelImplementationId || null
    );
  }, [
    assetDetails?.NameplateWithModelInfo?.ReplacementScoreModelImplementationId
  ]);

  const { leftPanelClassName, rightPanelClassName } = useMemo(
    () => getLeftRightPanelClassName(pageMode),
    [pageMode]
  );

  const implementationId = getModelImplementationId()?.toLowerCase();
  const conditionMonitoringType = getConditionMonitoringType();

  const collapsePanel = useCallback(() => {
    issueRef.current?.reset();
    collapseRightPanel();
  }, [collapseRightPanel]);

  useEffect(() => {
    if (issueId) {
      expandRightPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        "detail-page-new" + (sidePanelExpanded ? " left-panel-expanded" : "")
      }
    >
      <AssetRibbon assetId={assetId} sidePanelExpanded={sidePanelExpanded} />
      <div className="panels-container">
        <div
          className={
            "panel-left " +
            leftPanelClassName +
            (isParametersListWidgetVisible ? " minWidthContent" : "")
          }
        >
          <DetailPageWidgets
            assetId={assetId}
            issueId={issueId}
            assetType={getAssetType()}
            lifecycleStatus={getLifecycleStatus()}
            analyticsAvailable={
              implementationId ===
              AssetImplementationId.AbbTransformerStandardModel
            }
            dcTransformerAvailable={
              implementationId === AssetImplementationId.DcTransformer
            }
            kinectricsNTAvailable={isKinectricsNetworkTransformerModel(
              implementationId
            )}
            batteryAvailable={
              implementationId === AssetImplementationId.Battery
            }
            cbkAvailable={
              implementationId === AssetImplementationId.CircuitBreaker &&
              (conditionMonitoringType === null ||
                conditionMonitoringType !==
                  ConditionMonitoringType.CircuitBreaker)
            }
            cbkConditionMonitoringAvailable={
              conditionMonitoringType === ConditionMonitoringType.CircuitBreaker
            }
            modelId={assetDetails?.NameplateWithModelInfo?.ModelId || null}
            implementationId={getModelImplementationId()}
            replacementScoreModelImplementationId={getReplacementScoreModelImplementationId()}
            expanded={isChartWidgetsExpanded()}
          />
        </div>
        {pageMode !== PageModes.Left4Right0 && (
          <div
            className={"panel-right " + rightPanelClassName}
            data-qa="detailpage-action-plan"
          >
            <TitlePanel
              intl={intl}
              pageMode={pageMode}
              allowToCollapse={allowToCollapse}
              expandPanel={expandRightPanel}
              collapsePanel={collapsePanel}
            />
            <div className="content">
              <IssuesContainer
                assetId={assetId}
                issueId={issueId}
                lifecycleStatus={getLifecycleStatus()}
                ref={issueRef}
                isComponentLevel={getParentId() !== null}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const DetailPageContentWithIntl = injectIntl(DetailPageContent);

const DetailPageContextConsumer = (props: IDetailPageContentOwnProps) => {
  const { isParametersListWidgetVisible } = useContext(DetailPageWidgetContext);
  return (
    <DetailPageContentWithIntl
      {...props}
      isParametersListWidgetVisible={isParametersListWidgetVisible}
    />
  );
};

export default (props: IDetailPageContentOwnProps) => (
  <DetailPageWidgetContextProvider>
    <DetailPageContextConsumer {...props} />
  </DetailPageWidgetContextProvider>
);
