// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Switch, { ISwitchItem } from "common/form/components/Switch";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import { useCallback, useEffect, useState } from "react";

export enum IssueSwitchType {
  History = "history",
  Active = "active"
}

export enum IssueTypes {
  Active,
  Closed
}

const items: ISwitchItem[] = [
  {
    id: IssueSwitchType.Active,
    message: {
      id: "detail_page.issues.issue_status.active",
      defaultMessage: "Active"
    }
  },
  {
    id: IssueSwitchType.History,
    message: {
      id: "detail_page.issues.issue_status.history",
      defaultMessage: "History"
    }
  }
];

interface IIssueTypeSwitchProps {
  disabled?: boolean;
  issueId: string;
  activeIssues: IIssue[];
  type: IssueSwitchType;
  onActiveSelected?: () => void;
  onHistorySelected?: (id?: string) => void;
}

const IssueTypeSwitch = ({
  disabled,
  type,
  issueId,
  activeIssues,
  onActiveSelected,
  onHistorySelected
}: IIssueTypeSwitchProps) => {
  const [isOnRenderTypeSet, setIsOnRenderTypeSet] = useState(true);

  const handleSwitchChanged = useCallback(
    (id: string) => {
      if (id === IssueSwitchType.Active && onActiveSelected) {
        onActiveSelected();
      }
      if (id === IssueSwitchType.History && onHistorySelected) {
        onHistorySelected();
      }
    },
    [onActiveSelected, onHistorySelected]
  );

  const getIsInitialIssueTypeClosed = useCallback(() => {
    if (
      !issueId ||
      !activeIssues ||
      activeIssues?.find((issue) => issue.Id === issueId)
    ) {
      return false;
    }

    return true;
  }, [activeIssues, issueId]);

  useEffect(() => {
    if (!isOnRenderTypeSet) {
      return;
    }

    if (activeIssues) {
      setIsOnRenderTypeSet(false);
    }

    // set initial type to history if issue is closed
    if (getIsInitialIssueTypeClosed() || type === IssueSwitchType.History) {
      onHistorySelected(issueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIssues]);

  return (
    <Switch
      defaultSelected={
        getIsInitialIssueTypeClosed() ? IssueSwitchType.History : type
      }
      className="issue-type-switch"
      disabled={disabled}
      items={items}
      onChange={handleSwitchChanged}
    />
  );
};

export default IssueTypeSwitch;
