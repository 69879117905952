import { PageModes } from "features/detailpage/models/PagesModes";

const getLeftRightPanelClassName = (pageMode: PageModes) => {
  switch (pageMode) {
    case PageModes.Left3Right1:
      return {
        leftPanelClassName: "panel-3",
        rightPanelClassName: "panel-1"
      };
    case PageModes.Left2Right2:
      return {
        leftPanelClassName: "panel-2",
        rightPanelClassName: "panel-2"
      };
    case PageModes.Left4Right0:
      return {
        leftPanelClassName: "panel-4",
        rightPanelClassName: "panel-0"
      };
    default:
      return {
        leftPanelClassName: "",
        rightPanelClassName: ""
      };
  }
};

export default getLeftRightPanelClassName;
