// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const getIsKinectricsTransformerMineralOilIEEEModel = (
  implementationId: string
) => {
  return (
    implementationId?.toLowerCase() ===
    AssetImplementationId.KinectricsTransformerMineralOilIEEE
  );
};

export default getIsKinectricsTransformerMineralOilIEEEModel;
