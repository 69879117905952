// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { useMemo } from "react";
import { Location } from "react-router";

const issueIdSearchParam = "issueId";

const useIssueId = (location: Location) => {
  const searchParams = useMemo(
    () => new SearchParams(location.search),
    [location]
  );

  const issueId = useMemo(
    () =>
      searchParams.has(issueIdSearchParam)
        ? searchParams.get(issueIdSearchParam)
        : null,
    [searchParams]
  );

  return issueId;
};

export default useIssueId;
