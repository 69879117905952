// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IRegion } from "@apm/widgets/build/widgets/ParameterCharts";
import { isEmpty, isNil, pickBy } from "lodash";
import { colorStatusGreen } from "styles/ColorVariables";
import IThresholds from "../models/IThresholds";
import getConditionN from "./getConditionN";
import getThresholdColor from "./getThresholdColor";

const getNextValue = (value: number, thresholdValues: number[]) => {
  const index = thresholdValues.lastIndexOf(value);
  return index >= 0 && thresholdValues[index + 1]
    ? thresholdValues[index + 1]
    : null;
};

const getPreviousValue = (value: number, thresholdValues: number[]) => {
  const index = thresholdValues.indexOf(value);
  return index >= 0 && thresholdValues[index - 1]
    ? thresholdValues[index - 1]
    : null;
};

const mapToRegions = (
  thresholds: IThresholds,
  reversed: boolean
): IRegion[] => {
  if (thresholds && !isEmpty(thresholds)) {
    const validThresholds = pickBy(
      thresholds,
      (value) => !isNil(value[0]?.Value)
    );
    const sortedThresholdValues: number[] = Object.entries(validThresholds).map(
      ([, value]) => value[0].Value
    );

    sortedThresholdValues.sort(function (a, b) {
      return a - b;
    });

    const regions: IRegion[] = [];

    regions.push({
      name: "Condition1",
      color: colorStatusGreen,
      start: reversed ? null : sortedThresholdValues.at(-1),
      end: reversed ? sortedThresholdValues.at(0) : null
    });

    Object.entries(validThresholds).forEach(([key, value]) => {
      const start = reversed
        ? value[0].Value
        : getPreviousValue(value[0].Value, sortedThresholdValues);

      const end = reversed
        ? getNextValue(value[0].Value, sortedThresholdValues)
        : value[0].Value;

      regions.push({
        name: `Condition${getConditionN(key)}`,
        color: getThresholdColor(key),
        start,
        end
      });
    });

    return regions;
  }
};

export default mapToRegions;
