// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  Select,
  Typography
} from "antd";
import { formatDateForDatePicker } from "common/DateTime/utils/dateFormatters";
import Modal from "common/Modal";
import { isNil } from "lodash";
import { TypedValue, TypedValueEnum } from "models/ITypedValue";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { colorGray0, colorWidgetBorder } from "styles/ColorVariables";
import {
  spacingLarge,
  spacingMedium,
  spacingXLarge,
  spacingXXLarge
} from "styles/StyleVariables";
import ISelectOption from "../../../models/ISelectOption";
import boolValues from "../constants/boolValues";
import AssetModalContext from "../contexts/AssetModalContext";

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

export interface ICustomParameterProps {
  visible: boolean;
  className?: string;
  parameterName?: string;
  parameterValue?: string | number | boolean | Date;
  parameterType?: TypedValue;
  onCancel: () => void;
  onSave: (
    previousParameterName: string,
    parameterName: string,
    parameterValue: string | number | boolean | Date,
    parameterType: TypedValue
  ) => void;
  onDelete: (attributeName: string) => void;
  forbiddenParameterNames?: string[];
  availableParameterNames?: string[];
}

interface IFormValues {
  Label: string;
  DataType: TypedValue;
  DecimalValue: number;
  DateValue: Date;
  BoolValue: string;
  StringValue: string;
}

const CustomParameter = ({
  visible,
  parameterName,
  parameterValue,
  parameterType,
  onCancel,
  onSave,
  onDelete,
  className,
  forbiddenParameterNames,
  availableParameterNames
}: ICustomParameterProps) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const [dataType, setDataType] = useState<TypedValue>(
    parameterType ?? "Decimal"
  );

  const { validateMessages } = useContext(AssetModalContext);
  useEffect(() => {
    setDataType("Decimal");
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterName, parameterValue, parameterType, visible]);

  useEffect(() => {
    setDataType(parameterType ?? "Decimal");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterType]);

  const parameterNamesAutoCompleteOptions: ISelectOption[] = useMemo(
    () =>
      availableParameterNames.map((param) => ({ value: param, label: param })),
    [availableParameterNames]
  );

  const handleFinish = useCallback(
    (values: IFormValues) => {
      switch (values.DataType) {
        case "Decimal":
          onSave(
            parameterName,
            values.Label,
            values.DecimalValue,
            values.DataType
          );
          break;
        case "Bool": {
          onSave(
            parameterName,
            values.Label,
            values.BoolValue === "true" ? true : false,
            values.DataType
          );
          break;
        }
        case "DateTime":
          onSave(
            parameterName,
            values.Label,
            values.DateValue,
            values.DataType
          );
          break;
        case "String":
          onSave(
            parameterName,
            values.Label,
            values.StringValue,
            values.DataType
          );
          break;
      }
      if (parameterName) onCancel();
      form.resetFields();
    },
    [parameterName, onSave, onCancel, form]
  );

  const onDataTypeChange = ({ DataType }: { DataType: TypedValue }) => {
    if (!isNil(DataType)) setDataType(DataType);
  };

  const decimalTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "configuration_tool.label.decimal",
        defaultMessage: "Number"
      }),
    [intl]
  );

  const dateTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "configuration_tool.label.date",
        defaultMessage: "Date"
      }),
    [intl]
  );

  const stringTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "configuration_tool.label.string",
        defaultMessage: "String"
      }),
    [intl]
  );

  const booleanTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "configuration_tool.label.boolean",
        defaultMessage: "Boolean"
      }),
    [intl]
  );

  return (
    <Modal
      centered
      width="50%"
      open={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      className={className}
      data-qa="CustomParameterModal"
    >
      <Form<IFormValues>
        form={form}
        layout="vertical"
        colon={false}
        onFinish={handleFinish}
        validateMessages={validateMessages}
        onValuesChange={onDataTypeChange}
      >
        <Layout>
          <Header>
            <Title level={5}>
              {parameterName ? (
                <FormattedMessage
                  defaultMessage="Edit custom parameter"
                  id="configuration_tool.action.edit_custom_parameter"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Create custom parameter"
                  id="configuration_tool.action.create_custom_parameter"
                />
              )}
            </Title>
          </Header>
          <Content>
            <div className="item float-left">
              <Form.Item
                label={
                  <Text strong={true}>
                    <FormattedMessage
                      id="configuration_tool.label.label"
                      defaultMessage="Label"
                    />
                  </Text>
                }
                name="Label"
                initialValue={parameterName}
                rules={[
                  {
                    required: true,
                    whitespace: true
                  },
                  {
                    type: "string",
                    max: 128
                  },
                  {
                    validator(_, value) {
                      if (
                        !value ||
                        !forbiddenParameterNames.some((name) => {
                          if (
                            name?.toLowerCase() === parameterName?.toLowerCase()
                          )
                            return false;
                          else
                            return name?.toLowerCase() === value?.toLowerCase();
                        })
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        intl.formatMessage({
                          id: "configuration_tool.validation.parameter_name_is_already_defined",
                          defaultMessage: "Parameter name is already defined"
                        })
                      );
                    }
                  }
                ]}
              >
                <AutoComplete
                  allowClear={true}
                  showSearch={true}
                  options={parameterNamesAutoCompleteOptions || []}
                  filterOption={(input, option) =>
                    option.label
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </div>
            <div className="item parameter-type float-left">
              <Form.Item
                label={<Text strong={true} />}
                name="DataType"
                className="parameter-type"
                initialValue={dataType}
              >
                <Select>
                  <Option
                    key={TypedValueEnum.Decimal}
                    value={TypedValueEnum.Decimal}
                  >
                    {decimalTranslation}
                  </Option>
                  <Option
                    key={TypedValueEnum.DateTime}
                    value={TypedValueEnum.DateTime}
                  >
                    {dateTranslation}
                  </Option>
                  <Option
                    key={TypedValueEnum.String}
                    value={TypedValueEnum.String}
                  >
                    {stringTranslation}
                  </Option>
                  <Option key={TypedValueEnum.Bool} value={TypedValueEnum.Bool}>
                    {booleanTranslation}
                  </Option>
                </Select>
              </Form.Item>
            </div>
            <div className="item float-right">
              {dataType === TypedValueEnum.Decimal && (
                <Form.Item
                  label={
                    <Text strong={true}>
                      <FormattedMessage
                        id="configuration_tool.label.value"
                        defaultMessage="Value"
                      />
                    </Text>
                  }
                  name="DecimalValue"
                  initialValue={
                    parameterType === "Decimal" && parameterValue
                      ? parameterValue
                      : null
                  }
                  rules={[
                    { required: true },
                    {
                      type: "number",
                      min: -9999999999,
                      max: 9999999999
                    }
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              )}
              {dataType === TypedValueEnum.DateTime && (
                <Form.Item
                  label={
                    <Text strong={true}>
                      <FormattedMessage
                        id="configuration_tool.label.value"
                        defaultMessage="Value"
                      />
                    </Text>
                  }
                  name="DateValue"
                  initialValue={
                    parameterType === "DateTime" && parameterValue
                      ? parameterValue
                      : null
                  }
                  rules={[{ required: true }]}
                >
                  <DatePicker format={formatDateForDatePicker(intl)} />
                </Form.Item>
              )}
              {dataType === TypedValueEnum.Bool && (
                <Form.Item
                  label={
                    <Text strong={true}>
                      <FormattedMessage
                        id="configuration_tool.label.value"
                        defaultMessage="Value"
                      />
                    </Text>
                  }
                  name="BoolValue"
                  initialValue={parameterType === "Bool" && parameterValue}
                  rules={[{ required: true }]}
                >
                  <Select showSearch allowClear>
                    {boolValues.map((value) => (
                      <Option key={value.toString()} value={value.toString()}>
                        {value.toString()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {dataType === TypedValueEnum.String && (
                <Form.Item
                  label={
                    <Text strong={true}>
                      <FormattedMessage
                        id="configuration_tool.label.value"
                        defaultMessage="Value"
                      />
                    </Text>
                  }
                  name="StringValue"
                  initialValue={
                    parameterType === "String" && parameterValue
                      ? parameterValue
                      : ""
                  }
                  rules={[
                    { required: true, whitespace: true },
                    {
                      type: "string",
                      max: 4000
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </div>
          </Content>
          <Footer>
            {parameterName && (
              <Button
                type="text"
                className="float-left"
                danger
                onClick={() => {
                  onDelete(parameterName);
                  onCancel();
                }}
              >
                <FormattedMessage
                  defaultMessage="Delete"
                  id="configuration_tool.action.delete"
                />
              </Button>
            )}
            <Button type="primary" className="float-right" htmlType="submit">
              <Icon name="done" size="sm" />
              <FormattedMessage
                defaultMessage="Save"
                id="configuration_tool.action.save"
              />
            </Button>
            <Button
              type="default"
              className="btn-cancel float-right"
              onClick={onCancel}
            >
              <Icon name="close" size="sm" />
              <FormattedMessage
                defaultMessage="Cancel"
                id="configuration_tool.action.cancel"
              />
            </Button>
          </Footer>
        </Layout>
      </Form>
    </Modal>
  );
};

const StyledCustomParameter = styled(CustomParameter)`
  padding-bottom: 0px;

  .ant-layout,
  .ant-layout-header,
  .ant-layout-footer {
    background-color: ${colorGray0};
  }

  .ant-layout-header {
    padding: 0px 0px ${spacingXXLarge} 0px;
  }

  .ant-layout-content {
    padding-bottom: ${spacingXLarge};
    display: flex;
    .item {
      width: 100%;
      .ant-form-item-label {
        max-height: 30px;
      }
    }
    .ant-form-item {
      width: 100%;
      .ant-input-number,
      .ant-picker {
        width: 100%;
      }
    }
    .item.parameter-type {
      width: 100px;
      min-width: 120px;
      margin-left: ${spacingXXLarge};
      margin-right: ${spacingXXLarge};
    }
  }

  .ant-layout-footer {
    padding: ${spacingXXLarge} 0px 0px 0px;
    border-top: 1px solid;
    border-color: ${colorWidgetBorder};

    button {
      .icon {
        margin-right: ${spacingMedium};
      }
    }

    button + button {
      margin-right: ${spacingLarge};
    }
  }
`;

export default StyledCustomParameter;
