// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export interface IGasLabels {
  H2: string,
  C2H2: string,
  C2H4: string,
  CH4: string,
  C2H6: string
} 

const gasLabels: IGasLabels = {
  H2: "H₂",
  C2H2: "C₂H₂",
  C2H4: "C₂H₄",
  CH4: "CH₄",
  C2H6: "C₂H₆"
};

export default gasLabels;