// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { SearchParams } from "@pg/common";
import { NotificationContext } from "@pg/common/build/components/Notifications";
import Container from "common/Container";
import DataGrid, { IColumn, IRow } from "common/datagrid/DataGrid";
import { IColumnOrder } from "common/datagrid/models/IColumnOrder";
import SortOrders from "common/datagrid/models/SortOrders";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import SectionName from "components/common/SectionName";
import { routes } from "core/app/components/AppRoutes";
import { UserRoles } from "core/app/components/auth/Authorization";
import { useAppNavigate } from "core/app/components/RouterProvider";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import getCustomerVisualizationsIssueGridSelector from "core/selectors/getCustomerVisualizationsIssueGridSelector";
import IssueStatusChangeConfirmationModalContainer from "features/detailpage/features/issues/containers/IssueStatusChangeConfirmationModalContainer";
import saveAs from "file-saver";
import { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { useAppSelector } from "store";
import { config } from "utils/AppConfig";
import isRowLinkClicked from "utils/isRowLinkClicked";
import useIssueGrid from "../hooks/useIssueGrid";
import {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "../hooks/useIssuesGridColumnsConfig";
import CheckedBar from "./CheckedBar";
import IssuesLimitConfirmationModal from "./IssuesLimitConfirmationModal";

interface IIssueGridState {
  user: Data<IUser>;
}

interface IIssueGridOwnProps {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onMaintenancePriorityRequest?: () => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
}

type IIssueGridProps = IIssueGridOwnProps & IIssueGridState;

const DEFAULT_CHECKED_ROWS_LIMIT = 50;

const IssuesGrid = ({
  filters,
  onMaintenancePriorityClick,
  onMaintenancePriorityRequest,
  onNumberOfActionsClick,
  user
}: IIssueGridProps) => {
  const intl = useIntl();
  const navigate = useAppNavigate();
  const [isLimitModalVisible, setIsLimitModalVisible] = useState(false);

  const customerVisualizationsIssueGrid = useAppSelector(
    getCustomerVisualizationsIssueGridSelector
  );

  const isAdmin = AuthorizationService.isAuthorized(user.data, [
    UserRoles.Administrator
  ]);

  const checkedRowsLimit =
    customerVisualizationsIssueGrid.data?.IssueUpdateLimit ||
    DEFAULT_CHECKED_ROWS_LIMIT;

  const {
    actions,
    dataEndpoint,
    rowsTotal,
    columns,
    modalVisible,
    modalTitle,
    checkbox,
    shouldUpdateGridData,
    handleCloseIssueConfirm,
    handleChangeIssueConfirm,
    setCheckedRowsUnderLimit,
    hideCloseIssueModal,
    getAreActionsAvailable
  } = useIssueGrid({
    filters,
    user: user.data,
    checkedRowsLimit,
    onMaintenancePriorityClick,
    onMaintenancePriorityRequest,
    onNumberOfActionsClick,
    setIsLimitModalVisible
  });
  const { notifications } = useContext(NotificationContext);

  const { checkedRows } = checkbox;

  const stringToSortOrder = useCallback((sortOrder: SortOrders): string => {
    switch (sortOrder) {
      case SortOrders.Asc:
        return "asc";
      case SortOrders.Desc:
        return "desc";
      default:
        return "none";
    }
  }, []);

  const mapColumns = useCallback(
    (columns: IColumn[]): IColumnOrder[] => {
      return columns
        .filter((c) => c.state.sortOrder !== SortOrders.None)
        .sort((a, b) => a.state.groupOrder - b.state.groupOrder)
        .map<IColumnOrder>((c) => ({
          columnId: c.config.id,
          sortOrder: stringToSortOrder(c.state.sortOrder)
        }));
    },
    [stringToSortOrder]
  );

  const handleExcelExportClick = useCallback(
    (columns: IColumn[]) => {
      const url = UrlService.getApiUrl(
        config.api.watchlist.issueExcelExportUrl
      );

      EndpointService.postBinary<Blob>(
        url,
        (_request, data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          saveAs(blob, "Issues.xlsx");
        },
        () => {
          notifications.error({
            message: intl.formatMessage(
              {
                id: "data_grid.footer.export.download.error",
                defaultMessage: "Downloading {name} failed"
              },
              {
                name: "Issues.xlsx"
              }
            )
          });
        },
        {
          search: filters.search,
          filters: filters.selects,
          order: mapColumns(columns)
        }
      );
    },
    [filters.search, filters.selects, intl, mapColumns, notifications]
  );

  const handleCsvExportClick = useCallback(
    (columns: IColumn[]) => {
      const url = UrlService.getApiUrl(config.api.watchlist.issueCsvExportUrl);
      EndpointService.postBinary<Blob>(
        url,
        (_request, data) => {
          const blob = new Blob([data], {
            type: "application/csv"
          });
          saveAs(blob, "Issues.csv");
        },
        () => {
          notifications.error({
            message: intl.formatMessage(
              {
                id: "data_grid.footer.export.download.error",
                defaultMessage: "Downloading {name} failed"
              },
              {
                name: "Issues.csv"
              }
            )
          });
        },
        {
          search: filters.search,
          filters: filters.selects,
          order: mapColumns(columns)
        }
      );
    },
    [filters.search, filters.selects, intl, mapColumns, notifications]
  );

  const onRowClick = useCallback(
    (row: IRow, e: React.MouseEvent<HTMLElement>) => {
      if (isRowLinkClicked(e.target)) return;

      const searchParams = new SearchParams({
        assetId: row.data["AssetId"],
        issueId: row.data["AssetIssueId"]
      });

      navigate({
        pathname: routes.detailPage.pathname,
        search: searchParams.toString()
      });
    },
    [navigate]
  );

  return (
    <div className="grid-container data-grid__parent">
      <div className="data-grid__scroll">
        <Container size="lg">
          <div className="bootstrap-row">
            <div className="col-24 col-lg-24 header">
              {rowsTotal !== undefined && rowsTotal != null ? (
                <SectionName
                  messageDefault="Issues / {numberOfIssues, number} items"
                  messageId="issues_page.header.issues_total"
                  messageValues={{
                    numberOfIssues: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Issues"
                  messageId="issues_page.header.issues"
                />
              )}
              <StatusBar />
              <CheckedBar
                rows={checkedRows}
                disabled={!isAdmin}
                handleChange={handleChangeIssueConfirm}
              />
            </div>
          </div>
          <div className="bootstrap-row">
            <div className="col-24 col-lg-24">
              <DataGrid
                actions={actions}
                columns={columns}
                rowsTotal={rowsTotal}
                checkbox={isAdmin ? checkbox : undefined}
                dataEndpoint={dataEndpoint}
                shouldPullServerData={shouldUpdateGridData}
                getAreActionsAvailable={getAreActionsAvailable}
                onRowClick={onRowClick}
                exportToExcel={{
                  disabled:
                    !customerVisualizationsIssueGrid.data
                      ?.ExportToExcelRowLimit ||
                    customerVisualizationsIssueGrid.data
                      ?.ExportToExcelRowLimit < rowsTotal,
                  disabledMessage: intl.formatMessage(
                    {
                      id: "issues_page.grid.export_to_excel.disabled_message",
                      defaultMessage:
                        "The number of the issues in the page is more than the maximum number ({maxNumberOfIssues}) that you can export to an Excel file. Use the filtering options at the top of the page to decrease the number of issues."
                    },
                    {
                      maxNumberOfIssues: !customerVisualizationsIssueGrid.data
                        ?.ExportToExcelRowLimit
                        ? 0
                        : customerVisualizationsIssueGrid.data
                            ?.ExportToExcelRowLimit
                    }
                  ),
                  onClick: handleExcelExportClick
                }}
                exportToCsv={{
                  disabled: false,
                  onClick: handleCsvExportClick
                }}
              />
            </div>
          </div>
        </Container>
        <IssueStatusChangeConfirmationModalContainer
          visible={modalVisible}
          status={IssueStatuses.Closed}
          title={modalTitle}
          onConfirmAction={handleCloseIssueConfirm}
          hideModal={hideCloseIssueModal}
        />
        <IssuesLimitConfirmationModal
          limit={checkedRowsLimit}
          visible={isLimitModalVisible}
          hideModal={() => setIsLimitModalVisible(false)}
          onConfirmAction={setCheckedRowsUnderLimit}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState): IIssueGridState => ({
  user: getUserSelector(state)
});
const IssuesGridContainer = connect(mapStateToProps)(IssuesGrid);
export default IssuesGridContainer;
