// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import DielectricParameters from "@apm/widgets/build/widgets/DielectricParameters";
import { INotifications } from "@pg/common/build/components/Notifications";
import Processing from "components/common/Processing";
import ProcessingModal from "components/common/ProcessingModal";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data, { Statuses } from "core/data/models/Data";
import useIsCommandIntegrationEnabledData from "features/detailpage/hooks/useIsCommandIntegrationEnabledData";
import LifecycleStatus from "models/LifecycleStatus";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import acknowledge from "../../../../../actions/acknowledge";
import adjustExpectedRange from "../../../../../actions/adjustExpectedRange";
import IThresholdCorrection from "../../../../../models/IThresholdCorrection";
import loadPressureParameters from "../../SF6Parameters/actions/loadPressureParameters";
import removePressureParameters from "../../SF6Parameters/actions/removePressureParameters";
import getParametersSelector from "../../SF6Parameters/selectors/getParametersSelector";
import useSF6Parameters from "../hooks/useSF6Parameters";
import IDielectricPressureParametersData from "../models/IDielectricPressureParametersData";

interface ISF6ParametersActions {
  adjustExpectedRange: (
    intl: IntlShape,
    notificatios: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  acknowledge: (
    intl: IntlShape,
    notificatios: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void;
  loadParameters: () => void;
  removeParameters: () => void;
}

interface ISF6ParametersState {
  parameters: Data<IDielectricPressureParametersData>;
  user: Data<IUser>;
}

interface ISF6ParametersOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
}

interface ISF6ParametersProps
  extends ISF6ParametersActions,
    ISF6ParametersState,
    ISF6ParametersOwnProps {}

const SF6Parameters = ({
  assetId,
  lifecycleStatus,
  acknowledge,
  adjustExpectedRange,
  loadParameters,
  parameters,
  removeParameters,
  user
}: ISF6ParametersProps) => {
  const {
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    parametersMapped,
    parametersStatus,
    processing,
    translations
  } = useSF6Parameters(
    assetId,
    adjustExpectedRange,
    acknowledge,
    loadParameters,
    removeParameters,
    parameters
  );
  const isReadOnlyMode = useIsCommandIntegrationEnabledData(
    lifecycleStatus,
    user
  );
  return (
    <>
      {parametersStatus === Statuses.Loading && <Processing />}
      {parametersStatus === Statuses.Succeeded && parametersMapped && (
        <DielectricParameters
          formatValue={formatValue}
          onAcknowledgeClick={handleAcknowledgeClick}
          onAdjustExpectedRangeClick={handleAdjustExpectedRangeClick}
          parametersGroups={[parametersMapped]}
          thresholdCorrectionParams={["PressureAt20C", "RateLeakFromMonths"]}
          translations={translations}
          readonly={isReadOnlyMode}
        />
      )}
      {parametersStatus === Statuses.Succeeded && !parametersMapped && (
        <WidgetNoDataMessage />
      )}
      {parametersStatus === Statuses.NotFound && <WidgetNoDataMessage />}
      {parametersStatus === Statuses.Failed && <WidgetErrorMessage />}
      {processing && <ProcessingModal />}
    </>
  );
};

export default connect(
  (state: IState): ISF6ParametersState => ({
    parameters: getParametersSelector(state),
    user: getUserSelector(state)
  }),
  (
    dispatch: AppDispatch,
    { assetId }: ISF6ParametersOwnProps
  ): ISF6ParametersActions => ({
    adjustExpectedRange: (
      intl: IntlShape,
      notifications,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) =>
      adjustExpectedRange({
        intl,
        notifications,
        assetId,
        parameterName,
        success,
        error
      }),
    acknowledge: (
      intl: IntlShape,
      notifications: INotifications,
      parameterName: string,
      success?: (correction: IThresholdCorrection) => void,
      error?: () => void
    ) =>
      acknowledge({
        intl,
        notifications,
        assetId,
        parameterName,
        success,
        error
      }),
    loadParameters: () => dispatch(loadPressureParameters(assetId)),
    removeParameters: () => dispatch(removePressureParameters())
  })
)(SF6Parameters);
