// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IOilSettings, { OilTest } from "../models/IOilSettings";
import SotTypes from "../models/SotTypes";

const chartSettings: {
  [key in SotTypes]: { [key in OilTest]?: IOilSettings };
} = {
  [SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER]: {
    AcidNumber: {},
    Color: {},
    DielectricStrength: {},
    DissipationFactor: {},
    InterfacialTension: {},
    WaterContent: {}
  },
  [SotTypes.MINERAL_OIL_IEEE_NETWORK_TRANSFORMER]: {
    InterfacialTension: {},
    WaterContent: {},
    PowerFactor: {
      series: ["PowerFactor25C", "PowerFactor100C"]
    },
    DielectricStrength: {},
    AcidNumber: {},
    Color: {}
  },
  [SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER]: {
    AcidNumber: {},
    Color: {},
    DielectricStrength: {},
    PowerFactor25C: {},
    WaterContent: {}
  },
  [SotTypes.IEC]: {
    Moisture: {},
    AcidNumber: {},
    DielectricStrength: {},
    InterfacialTension: {},
    PowerFactor90C: {},
    InhibitorContent: {}
  },
  [SotTypes.IEEE]: {
    InterfacialTension: {},
    Moisture: {},
    PowerFactor: {
      series: ["PowerFactor25C", "PowerFactor100C"]
    },
    DielectricStrength: {},
    AcidNumber: {},
    InhibitorContent: {}
  }
};

export default chartSettings;
