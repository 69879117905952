// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IThreshold } from "@apm/widgets/build/widgets/ParameterCharts";
import {
  colorBlue60,
  colorStatusGreen,
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import IOilSettings from "../models/IOilSettings";
import { IChartsCollection } from "../models/ISOTData";
import { IThreshold as IChartDataThreshold } from "../models/IThresholds";
import { PowerFactorType } from "../models/PowerFactorTypeEnums";

const getColor = (name: string) => {
  switch (name) {
    case "Alarm":
    case "Poor":
      return colorStatusRed;
    case "Alert":
    case "Fair":
    case PowerFactorType.PF25:
      return colorStatusOrange;
    case "Good":
      return colorStatusGreen;
    case PowerFactorType.PF100:
      return colorBlue60;
    case "Min":
    case "Max":
      return colorBlue60;
    default:
      return undefined;
  }
};

const getDisplayName = (
  thresholdName: string,
  sotType: string,
  seriesName: string,
  intl: IntlShape
) =>
  intl.formatMessage({
    defaultMessage: `${thresholdName} (${seriesName})`,
    id: `detail_page.widgets.analytics.transformers.sot.${sotType}.${seriesName}_${thresholdName}`
  });

const getSections = (threshold: IChartDataThreshold[]): IThreshold => {
  return threshold?.map((t) => ({
    start: t.Start,
    end: t.End,
    value: t.Value
  }));
};

const mapToThresholds = (
  chartsData: IChartsCollection,
  name: string,
  settings: IOilSettings,
  sotType: string,
  intl: IntlShape
) => {
  if (settings.series) {
    return settings.series.map((seriesName) => {
      const thresholdEntry = Object.entries(
        chartsData[seriesName].Thresholds
      )[0];
      return {
        name: `${name}_${seriesName}`,
        displayName: getDisplayName(
          thresholdEntry[0],
          sotType,
          seriesName,
          intl
        ),
        seriesName: `${seriesName}_offline`,
        color: getColor(seriesName),
        isAreaColored: false,
        sections: getSections(thresholdEntry[1])
      };
    });
  } else {
    const thresholdList: IThreshold[] = Object.entries(
      chartsData[name].Thresholds
    )?.map<IThreshold>(([thresholdName, thresholdValues], i) => {
      return {
        name: `${name}_${thresholdName}`,
        displayName: getDisplayName(thresholdName, sotType, name, intl),
        color: getColor(thresholdName),
        isAreaColored: false,
        sections: getSections(thresholdValues)
      };
    });

    return thresholdList?.sort(
      (a, b) => a.sections?.[0]?.value - b.sections?.[0]?.value
    );
  }
};

export default mapToThresholds;
