// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import { Map } from "immutable";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo
} from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import ErrorMessage from "common/messages/ErrorMessage";
import Processing from "components/common/Processing";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data, { Statuses } from "core/data/models/Data";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IssueMeta from "features/detailpage/features/issues/models/IssueMeta";
import Issue from "./Issue";
import IssueList from "./IssueList";
import IssueTypeSwitch, {
  IssueSwitchType,
  IssueTypes
} from "./IssueTypeSwitch";

import { UserRoles } from "core/app/components/auth/Authorization";
import LifecycleStatus from "models/LifecycleStatus";
import { issuesLimit } from "../containers/IssuesContainer";
import IssueModes from "../models/IssueModes";
import getIssueListStatuses from "../utils/getIssueListStatuses";
import getNewIssueStatuses from "../utils/getNewIssueStatuses";
import "./Issues.less";

export interface IIssuesActions {
  createNewIssue: (
    userName: string,
    assetId: string,
    userSurname: string
  ) => string;
  expandPanel: () => void;
  loadClosedIssues: () => void;
  loadClosedIssuesWithLimit: () => void;
  removeNewIssue: () => void;
  switchIssueModeToView: (issueId: string) => void;
  startProcessingIssues: () => void;
}

export interface IIssuesData {
  newIssue: IIssue;
  newMeta: IssueMeta;
  activeIssues: Data<IIssue[]>;
  activeMetas: Map<string, IssueMeta>;
  closedIssues: Data<IIssue[]>;
  closedMetas: Map<string, IssueMeta>;
  statuses: Data<IssueStatuses[]>;
  urgencies: Data<string[]>;
  user: IUser;
}

export interface IIssuesOwnProps {
  assetId: string;
  lifecycleStatus: LifecycleStatus;
  isComponentLevel?: boolean;
  issueId?: string;
}

export interface IIssuesProps
  extends IIssuesActions,
    IIssuesData,
    IIssuesOwnProps {}

export interface IIssuesState {
  currentIssueId: string;
  issueType: IssueTypes;
  user: IUser;
}

const TIMEOUT_BEFORE_SCROLL = 100;

const Issues = forwardRef((props: IIssuesProps, ref) => {
  const {
    user,
    assetId,
    newIssue,
    newMeta,
    activeIssues,
    activeMetas,
    closedIssues,
    closedMetas,
    statuses,
    urgencies,
    lifecycleStatus,
    isComponentLevel,
    issueId: issueIdProp,
    createNewIssue,
    expandPanel,
    startProcessingIssues,
    removeNewIssue,
    switchIssueModeToView,
    loadClosedIssues,
    loadClosedIssuesWithLimit
  } = props;

  const scrollSectionRef = React.useRef(null);

  const [currentIssueId, setCurrentIssueId] = React.useState(issueIdProp);
  const [issueType, setIssueType] = React.useState<IssueTypes>(
    IssueTypes.Active
  );

  const isActiveType = issueType === IssueTypes.Active;

  const { showIssueListLoading, showIssueListSucceeded, showIssueListFailed } =
    useMemo(
      () =>
        getIssueListStatuses({
          statuses,
          urgencies,
          isActiveType,
          activeIssues,
          closedIssues
        }),
      [statuses, urgencies, isActiveType, activeIssues, closedIssues]
    );

  const { showNewIssueLoading, showNewIssueSucceeded, showNewIssueFailed } =
    useMemo(
      () =>
        getNewIssueStatuses({
          statuses,
          urgencies,
          newIssue,
          newMeta
        }),
      [statuses, urgencies, newIssue, newMeta]
    );

  const showShowMoreButton =
    !isActiveType &&
    closedIssues.status === Statuses.Succeeded &&
    closedIssues.data.length === issuesLimit;

  const isAnyIssueInCreateOrEdit =
    (!!newIssue && !!newMeta && newMeta.mode === IssueModes.Create) ||
    activeMetas.some((m) => m.mode === IssueModes.Edit);

  const isReadOnly = useMemo(
    () =>
      !AuthorizationService.isAuthorized(user, [
        UserRoles.Administrator,
        UserRoles.Engineer,
        UserRoles.LimitedEngineer
      ]),
    [user]
  );

  const handleActiveSelected = () => {
    setCurrentIssueId(null);
    setIssueType(IssueTypes.Active);
  };

  const handleHistorySelected = (id?: string) => {
    setCurrentIssueId(id || null);
    setIssueType(IssueTypes.Closed);
    loadClosedIssuesWithLimit();
  };

  const handleCreateClick = () => {
    const issueId = createNewIssue(user.Name, assetId, user.Surname);

    setCurrentIssueId(issueId);
    expandPanel();
    startProcessingIssues();

    scrollSectionRef.current?.scrollTo(0, 0);
  };

  const scrollToElement = useCallback((element: HTMLDivElement) => {
    setTimeout(() => {
      const y =
        element.getBoundingClientRect().top -
        element.parentElement.getBoundingClientRect().top;

      scrollSectionRef.current?.scrollTo(0, y);
    }, TIMEOUT_BEFORE_SCROLL);
  }, []);

  const handleIssueClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, issueId: string) => {
      if (issueId !== currentIssueId) {
        setCurrentIssueId(issueId);
        expandPanel();
        scrollToElement(e.currentTarget);
      }
    },
    [currentIssueId, expandPanel, scrollToElement]
  );

  const handleIssueSaved = useCallback(() => setCurrentIssueId(null), []);

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (newIssue) {
        removeNewIssue();
      } else {
        switchIssueModeToView(currentIssueId);
      }

      setCurrentIssueId(null);
    }
  }));

  // if the issues was closed behind the scenes, we need to filter them out from the active issues list
  const filteredActiveIssuesData = useMemo(
    () =>
      activeIssues?.data?.filter(
        (issue) => issue.Status !== IssueStatuses.Closed
      ),
    [activeIssues.data]
  );

  return (
    <div className="details-page-issues">
      {!isReadOnly && (
        <Button
          type="primary"
          shape="circle"
          size="large"
          className="create-issue"
          disabled={
            !showIssueListSucceeded ||
            isAnyIssueInCreateOrEdit ||
            !isActiveType ||
            lifecycleStatus === LifecycleStatus.Removed
          }
          onClick={handleCreateClick}
        >
          <Icon name="add" />
        </Button>
      )}
      <IssueTypeSwitch
        issueId={issueIdProp}
        activeIssues={filteredActiveIssuesData}
        type={
          lifecycleStatus === LifecycleStatus.Removed
            ? IssueSwitchType.History
            : IssueSwitchType.Active
        }
        disabled={
          isAnyIssueInCreateOrEdit ||
          lifecycleStatus === LifecycleStatus.Removed
        }
        onActiveSelected={handleActiveSelected}
        onHistorySelected={handleHistorySelected}
      />
      <div className="scrollSection" ref={scrollSectionRef}>
        {showNewIssueSucceeded && isActiveType && (
          <Issue
            currentIssueId={currentIssueId}
            issue={newIssue}
            meta={newMeta}
            key={newIssue.Id}
            onClick={(e) => {
              handleIssueClick(e, newIssue.Id);
            }}
            onSaved={handleIssueSaved}
            onStatusSaved={handleIssueSaved}
            urgencies={urgencies.data}
            readonly={isReadOnly}
            isComponentLevel={isComponentLevel}
            scrollToElement={scrollToElement}
          />
        )}
        {showIssueListSucceeded && (
          <IssueList
            currentIssueId={currentIssueId}
            className={isActiveType ? "active" : "closed"}
            disabledIfView={isAnyIssueInCreateOrEdit}
            issues={isActiveType ? filteredActiveIssuesData : closedIssues.data}
            metas={isActiveType ? activeMetas : closedMetas}
            onIssueClick={handleIssueClick}
            onIssueSaved={handleIssueSaved}
            onIssueStatusSaved={handleIssueSaved}
            readonly={!isActiveType || isReadOnly}
            urgencies={urgencies.data}
            isComponentLevel={isComponentLevel}
            scrollToElement={scrollToElement}
          />
        )}
        {(showNewIssueLoading || showIssueListLoading) && <IssuesProcessing />}
        {showNewIssueFailed && (
          <IssueErrorMessage
            message={{
              defaultMessage: "Create new issue failed.",
              id: "detail_page.issues.create_issue_failed"
            }}
          />
        )}
        {showIssueListFailed && (
          <IssueErrorMessage
            message={{
              defaultMessage: "Loading issue list failed.",
              id: "detail_page.issues.loading_issues_failed"
            }}
          />
        )}
        {showShowMoreButton && (
          <div className="show-more-container">
            <Button type="link" className="light" onClick={loadClosedIssues}>
              <FormattedMessage
                defaultMessage="Show more"
                id="detail_page.issues.show_more"
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
});

const IssuesProcessing = () => (
  <div>
    <Processing />
  </div>
);

interface IIssueErrorMessageProps {
  message: MessageDescriptor;
}

const IssueErrorMessage = ({ message }: IIssueErrorMessageProps) => (
  <ErrorMessage message={message} />
);

export default Issues;
