// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import withNotifications from "common/Notifications/withNotifications";
import { UserRoles } from "core/app/components/auth/Authorization";
import AuthorizationContainer from "core/app/containers/AuthorizationContainer";
import ModelConfig from "features/dataintegration/ModelConfig";
import ModelsPanel from "features/dataintegration/ModelsPanel";
import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import DataIntegrationStore, {
  IModel
} from "store/dashboards/dataintegration/DataIntegrationStore";
import "./DataIntegration.less";

interface IDataIntegrationProps {
  intl: IntlShape;
  notifications: INotifications;
}

interface IDataIntegrationState {
  selectedModel: IModel;
}

class DataIntegration extends React.Component<
  IDataIntegrationProps,
  IDataIntegrationState
> {
  constructor(props: IDataIntegrationProps) {
    super(props);

    this.state = {
      selectedModel: null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    return (
      <AuthorizationContainer
        className="data-integration-container"
        roles={[UserRoles.Administrator]}
        showMessage={true}
      >
        <div className="data-integration">
          <ModelsPanel />
          <ModelConfig notifications={this.props.notifications} />
        </div>
      </AuthorizationContainer>
    );
  }

  public UNSAFE_componentWillMount() {
    DataIntegrationStore.addChangeListener(this.handleChange);
    DataIntegrationStore.initStore();
    DataIntegrationStore.loadModels(this.props.intl, this.props.notifications);
  }

  public componentWillUnmount() {
    DataIntegrationStore.removeChangeListener(this.handleChange);
  }

  private handleChange() {
    this.setState((prevState, props) => {
      return Object.assign({}, prevState, {
        selectedModel: DataIntegrationStore.getSelectedModel()
      });
    });
  }
}

export default injectIntl(withNotifications(DataIntegration));
