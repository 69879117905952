// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import Data, { Statuses } from "core/data/models/Data";
import IIssue from "../models/IIssue";
import IssueMeta from "../models/IssueMeta";

interface INewIssuesStatusesProps {
  statuses: Data<IssueStatuses[]>;
  newIssue: IIssue;
  newMeta: IssueMeta;
  urgencies: Data<string[]>;
}

const getNewIssueStatuses = ({
  statuses,
  newIssue,
  newMeta,
  urgencies
}: INewIssuesStatusesProps) => {
  const showNewIssueLoading =
    !!newIssue &&
    !!newMeta &&
    (statuses.status === Statuses.Loading ||
      urgencies.status === Statuses.Loading);

  const showNewIssueSucceeded =
    !!newIssue &&
    !!newMeta &&
    statuses.status === Statuses.Succeeded &&
    urgencies.status === Statuses.Succeeded;

  const showNewIssueFailed =
    !!newIssue &&
    !!newMeta &&
    (statuses.status === Statuses.Failed ||
      urgencies.status === Statuses.Failed);

  return {
    showNewIssueLoading,
    showNewIssueSucceeded,
    showNewIssueFailed
  };
};

export default getNewIssueStatuses;
