// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import Data, { Statuses } from "core/data/models/Data";
import IIssue from "../models/IIssue";

interface IIssueListStatusesProps {
  statuses: Data<IssueStatuses[]>;
  urgencies: Data<string[]>;
  isActiveType: boolean;
  activeIssues: Data<IIssue[]>;
  closedIssues: Data<IIssue[]>;
}

const getIssueListStatuses = ({
  statuses,
  urgencies,
  isActiveType,
  activeIssues,
  closedIssues
}: IIssueListStatusesProps) => {
  const showIssueListLoading =
    (isActiveType && activeIssues.status === Statuses.Loading) ||
    (!isActiveType && closedIssues.status === Statuses.Loading) ||
    statuses.status === Statuses.Loading ||
    urgencies.status === Statuses.Loading;

  const showIssueListSucceeded =
    ((isActiveType && activeIssues.status === Statuses.Succeeded) ||
      (!isActiveType && closedIssues.status === Statuses.Succeeded)) &&
    statuses.status === Statuses.Succeeded &&
    urgencies.status === Statuses.Succeeded;

  const showIssueListFailed =
    (isActiveType && activeIssues.status === Statuses.Failed) ||
    (!isActiveType && closedIssues.status === Statuses.Failed) ||
    statuses.status === Statuses.Failed ||
    urgencies.status === Statuses.Failed;

  return {
    showIssueListLoading,
    showIssueListSucceeded,
    showIssueListFailed
  };
};

export default getIssueListStatuses;
