// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Actions } from "core/app/actions/settings/CustomerActions";
import ICustomer from "core/app/models/ICustomer";
import IIssueStatusChangeSettings from "core/app/models/IIssueStatusChangeSettings";
import IVisualizationsAssetGrid from "core/app/models/IVisualizationsAssetGrid";
import IVisualizationsCommandIntegration from "core/app/models/IVisualizationsCommandIntegration";
import IVisualizationsComponentGrid from "core/app/models/IVisualizationsComponentGrid";
import IVisualizationsConfiguration from "core/app/models/IVisualizationsConfiguration";
import IVisualizationsIssueGrid from "core/app/models/IVisualizationsIssueGrid";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux";

export interface IState {
  customer: Data<ICustomer>;
  customerVisualizations: Data<IVisualizationsConfiguration>;
  customerVisualizationsAssetGrid: Data<IVisualizationsAssetGrid>;
  customerVisualizationsIssueGrid: Data<IVisualizationsIssueGrid>;
  customerVisualizationsComponentGrid: Data<IVisualizationsComponentGrid>;
  customerVisualizationsCommandIntegration: Data<IVisualizationsCommandIntegration>;
  customerIssueSettings: Data<IIssueStatusChangeSettings>;
  watchListLimit: Data<number>;
}

const customerReducer: Reducer<IState> = (
  state: IState,
  action:
    | IAjaxAction<ICustomer>
    | IAjaxAction<IVisualizationsConfiguration>
    | IAjaxAction<IIssueStatusChangeSettings>
    | IAjaxAction<number>
) => {
  switch (action.type) {
    case Actions.Settings_SetCustomer:
      return Object.assign({}, state, {
        customer: new Data(
          (action as IAjaxAction<ICustomer>).request,
          (action as IAjaxAction<ICustomer>).data
        )
      } as IState);
    case Actions.Settings_SetCustomerVisualizations:
      return {
        ...state,
        customerVisualizations: new Data(
          (action as IAjaxAction<IVisualizationsConfiguration>).request,
          (action as IAjaxAction<IVisualizationsConfiguration>).data
        )
      };
    case Actions.Settings_SetCustomerVisualizationsAssetGrid:
      return {
        ...state,
        customerVisualizationsAssetGrid: new Data(
          (action as IAjaxAction<IVisualizationsAssetGrid>).request,
          (action as IAjaxAction<IVisualizationsAssetGrid>).data
        )
      };
    case Actions.Settings_SetCustomerVisualizationsIssueGrid:
      return {
        ...state,
        customerVisualizationsIssueGrid: new Data(
          (action as IAjaxAction<IVisualizationsIssueGrid>).request,
          (action as IAjaxAction<IVisualizationsIssueGrid>).data
        )
      };
    case Actions.Settings_SetCustomerVisualizationsComponentGrid:
      return {
        ...state,
        customerVisualizationsComponentGrid: new Data(
          (action as IAjaxAction<IVisualizationsComponentGrid>).request,
          (action as IAjaxAction<IVisualizationsComponentGrid>).data
        )
      };
    case Actions.Settings_SetCustomerVisualizationsCommandIntegration:
      return {
        ...state,
        customerVisualizationsCommandIntegration: new Data(
          (action as IAjaxAction<IVisualizationsCommandIntegration>).request,
          (action as IAjaxAction<IVisualizationsCommandIntegration>).data
        )
      };
    case Actions.Settings_SetCustomerIssueSettings:
      return {
        ...state,
        customerIssueSettings: new Data(
          (action as IAjaxAction<IIssueStatusChangeSettings>).request,
          (action as IAjaxAction<IIssueStatusChangeSettings>).data
        )
      };
    case Actions.Settings_SetWatchListLimit:
      return {
        ...state,
        watchListLimit: new Data(
          (action as IAjaxAction<number>).request,
          (action as IAjaxAction<number>).data
        )
      };
    default:
      return state;
  }
};

export default customerReducer;
