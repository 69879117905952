// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import DataGrid, { IColumn } from "common/datagrid/DataGrid";
import DataGridWrapper from "common/datagrid/DataGridWrapper";
import { IEndpoint } from "common/datagrid/models/IEndpoint";
import DataService, {
  ContentTypes,
  ExportTypes
} from "common/datagrid/services/DataService";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import UrlService from "core/data/services/UrlService";
import getCustomerVisualizationsComponentGridSelector from "core/selectors/getCustomerVisualizationsComponentGridSelector";
import { isNil } from "lodash";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";
import styled from "styled-components";
import { spacingXLarge, spacingXXLarge } from "styles/StyleVariables";
import { config } from "utils/AppConfig";
import gridColumnsConfig from "../constants/gridColumnsConfig";
import useGridData from "../hooks/useGridData";

interface IComponentsGridProps {
  className?: string;
  filters: SelectedFilters;
}

const ComponentsGrid = ({ className, filters }: IComponentsGridProps) => {
  const intl = useIntl();

  const customerVisualizationsComponentGrid = useAppSelector(
    getCustomerVisualizationsComponentGridSelector
  );

  const { dataEndpoint, rowsTotal } = useGridData({
    filters
  });
  const { notifications } = useContext(NotificationContext);

  const handleExcelExportClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint: IEndpoint = {
        ...dataEndpoint,
        url: UrlService.getApiUrl(
          config.api.components.componentsExcelExportUrl
        ),
        content: { ...dataEndpoint.content, start: null, size: null }
      };
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Excel,
        ContentTypes.Components,
        null,
        intl,
        notifications
      );
    },
    [dataEndpoint, intl, notifications]
  );

  const handleCsvExportClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint: IEndpoint = {
        ...dataEndpoint,
        url: UrlService.getApiUrl(config.api.components.componentsCsvExportUrl),
        content: { ...dataEndpoint.content, start: null, size: null }
      };
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Csv,
        ContentTypes.Components,
        null,
        intl,
        notifications
      );
    },
    [dataEndpoint, intl, notifications]
  );

  return (
    <DataGridWrapper className={className}>
      <Header>
        {!isNil(rowsTotal) ? (
          <SectionName
            messageDefault="Components / { numberOfComponents, plural, zero {no items} one {# item} other {# items} }"
            messageId="components_page.header.components_total"
            messageValues={{
              numberOfComponents: rowsTotal
            }}
          />
        ) : (
          <SectionName
            messageDefault="Components"
            messageId="components_page.header.components"
          />
        )}
        <StatusBar />
      </Header>
      <DataGrid
        columns={gridColumnsConfig}
        dataEndpoint={dataEndpoint}
        exportToExcel={
          customerVisualizationsComponentGrid.data?.ExportToExcelRowLimit && {
            disabled:
              customerVisualizationsComponentGrid.data?.ExportToExcelRowLimit <
              rowsTotal,
            disabledMessage: intl.formatMessage(
              {
                id: "components_page.grid.export_to_excel.disabled_message",
                defaultMessage:
                  "The number of the components in the page is more than the maximum number ({maxNumberOfComponents}) that you can export to an Excel file. Use the filtering options at the top of the page to decrease the number of components."
              },
              {
                maxNumberOfComponents:
                  customerVisualizationsComponentGrid.data
                    ?.ExportToExcelRowLimit
              }
            ),
            onClick: handleExcelExportClick
          }
        }
        exportToCsv={
          customerVisualizationsComponentGrid.data?.ExportToCsvRowLimit && {
            disabled:
              customerVisualizationsComponentGrid.data?.ExportToCsvRowLimit <
              rowsTotal,
            disabledMessage: intl.formatMessage(
              {
                id: "components_page.grid.export_to_csv.disabled_message",
                defaultMessage:
                  "The number of the components in the page is more than the maximum number ({maxNumberOfComponents}) that you can export to a CSV file. Use the filtering options at the top of the page to decrease the number of components."
              },
              {
                maxNumberOfComponents:
                  customerVisualizationsComponentGrid.data?.ExportToCsvRowLimit
              }
            ),
            onClick: handleCsvExportClick
          }
        }
      />
    </DataGridWrapper>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:first-child {
    margin-right: ${spacingXLarge};
  }

  .status-panel {
    margin-left: ${spacingXXLarge};
    margin-right: auto;
  }
`;

export default ComponentsGrid;
