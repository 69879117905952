// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  INotifications,
  NotificationContext
} from "@pg/common/build/components/Notifications";
import Data from "core/data/models/Data";
import update from "immutability-helper";
import { isNil } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import useParametersTranslations from "../../../../../hooks/useParametersTranslations";
import IThresholdCorrection from "../../../../../models/IThresholdCorrection";
import IDielectricPressureParametersData from "../models/IDielectricPressureParametersData";
import SF6ParametersMapper from "../services/SF6ParametersMapper";

const useSF6Parameters = (
  assetId: string,
  adjustExpectedRange: (
    intl: IntlShape,
    notifications: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  acknowledge: (
    intl: IntlShape,
    notifications: INotifications,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  loadParameters: () => void,
  removeParameters: () => void,
  parameters: Data<IDielectricPressureParametersData>
) => {
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);
  const [processing, setProcessing] = useState(false);
  const { translations } = useParametersTranslations();
  const [thresholdsCorrections, setThresholdsCorrections] = useState<
    {
      parameterName: string;
      correction: IThresholdCorrection;
    }[]
  >([]);

  const parametersStatus = useMemo(
    () => parameters?.status,
    [parameters?.status]
  );

  const parametersData = useMemo(() => {
    if (isNil(parameters?.data)) return undefined;
    if (!thresholdsCorrections.length) return parameters.data;

    const updates: {
      parameterId: number;
      valueId: number;
      correction: IThresholdCorrection;
    }[] = [];

    parameters.data.Parameters?.forEach((parameter, i) => {
      parameter.Poles?.forEach((pole, j) => {
        const k = thresholdsCorrections.findIndex(
          (c) => c.parameterName === pole?.ExternalId
        );

        if (k >= 0) {
          updates.push({
            parameterId: i,
            valueId: j,
            correction: thresholdsCorrections[k].correction
          });
        }
      });
    });

    let newValue = parameters.data;
    updates.forEach((u) => {
      newValue = update(newValue, {
        Parameters: {
          [u.parameterId]: {
            Poles: {
              [u.valueId]: {
                UserThresholdCorrection: { $set: u.correction }
              }
            }
          }
        }
      });
    });

    return newValue;
  }, [thresholdsCorrections, parameters?.data]);

  const parametersMapped = useMemo(
    () =>
      parametersData
        ? new SF6ParametersMapper(intl).map(parametersData)
        : undefined,
    [intl, parametersData]
  );

  const formatValue = (value: number | string | null) => {
    if (typeof value === "number") {
      return intl.formatNumber(value, { maximumFractionDigits: 1 });
    } else if (value === null || value === undefined) {
      return "-";
    }

    return value;
  };

  const updateThresholdsCorrections = useCallback(
    (parameterName: string, correction: IThresholdCorrection) => {
      const i = thresholdsCorrections.findIndex(
        (c) => c.parameterName === parameterName
      );
      if (i < 0) {
        const newValue = update(thresholdsCorrections, {
          $push: [
            {
              parameterName,
              correction
            }
          ]
        });

        setThresholdsCorrections(newValue);
      } else {
        const newValue = update(thresholdsCorrections, {
          [i]: { correction: { $set: correction } }
        });

        setThresholdsCorrections(newValue);
      }
    },
    [thresholdsCorrections]
  );

  const handleAdjustExpectedRangeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      setProcessing(true);
      adjustExpectedRange(
        intl,
        notifications,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          setProcessing(false);
          if (success) success();
        },
        () => {
          setProcessing(false);
          if (error) error();
        }
      );
    },
    [adjustExpectedRange, intl, notifications, updateThresholdsCorrections]
  );

  const handleAcknowledgeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      setProcessing(true);
      acknowledge(
        intl,
        notifications,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          setProcessing(false);
          if (success) success();
        },
        () => {
          setProcessing(false);
          if (error) error();
        }
      );
    },
    [acknowledge, intl, notifications, updateThresholdsCorrections]
  );

  useEffect(() => {
    loadParameters();
    setThresholdsCorrections([]);
    return () => {
      removeParameters();
    };
  }, [loadParameters, removeParameters]);

  return {
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    parametersMapped,
    parametersStatus,
    processing,
    translations
  };
};

export default useSF6Parameters;
