// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { FormattedNumber } from "react-intl";

import { styled } from "styled-components";
import "./Actions.less";

interface IActionsProps {
  onClick: () => void;
  value: number;
}

const Actions = ({ onClick, value }: IActionsProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Link
      className="column actions anchor link-label"
      href="#/"
      onClick={handleClick}
    >
      <FormattedNumber value={value} />
    </Link>
  );
};

const Link = styled.a`
  height: 100%;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }
`;

export default Actions;
