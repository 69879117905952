// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { ReactNode } from "react";
import { IntlShape } from "react-intl";

const getGasDisplayName = (
  intl: IntlShape,
  gasName: string
): ReactNode =>
    <span>
      {intl.formatMessage(
        {
          defaultMessage: `${gasName}`,
          id: `detail_page.widgets.analytics.transformers.dga.${gasName}`
        }
      )}
    </span>

export default getGasDisplayName;
