// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  IChart,
  IChartSeries
} from "@apm/widgets/build/widgets/ParameterCharts";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import oilUnits from "../constants/oilUnits";
import IOilSettings, { OilTest } from "../models/IOilSettings";
import { IChartsCollection } from "../models/ISOTData";
import SotTypes from "../models/SotTypes";
import mapToThresholds from "./mapToThresholds";

const mapToChart = (
  chartsData: IChartsCollection,
  name: OilTest,
  settings: IOilSettings,
  sotType: SotTypes,
  intl: IntlShape
): IChart => {
  const displayName = intl.formatMessage({
    defaultMessage: `${name}`,
    id: `detail_page.widgets.analytics.transformers.sot.${sotType}.${name}.Title`
  });

  const areThresholdsEmpty =
    chartsData[name]?.Thresholds &&
    isEmpty(Object.values(chartsData[name]?.Thresholds)[0]);

  const adjustYSizeToPositiveValues =
    !settings.series && areThresholdsEmpty ? true : false;

  const chart: IChart = {
    name,
    displayName,
    adjustYSizeToPositiveValues,
    adjustYSizeToThresholds: true
  };

  if (!chartsData[name] && !settings.series) return chart;

  const createOnlineSeries = () => {
    const series: IChartSeries[] = [];
    chartsData[name] &&
      chartsData[name].OnlineTrends &&
      series.push({
        name: `${name}_online`,
        unit: oilUnits[name],
        values: chartsData[name].OnlineTrends
      });
    return series;
  };

  const createOfflineSeries = () => {
    const series: IChartSeries[] = [];
    if (settings.series) {
      settings.series.forEach((seriesName: OilTest, i) => {
        series.push({
          name: `${seriesName}_offline`,
          unit: `${oilUnits[seriesName]}${i + 1}`,
          values: chartsData[seriesName].OfflineTrends
        });
      });
    } else {
      chartsData[name].OfflineTrends &&
        series.push({
          name: `${name}_offline`,
          unit: oilUnits[name],
          values: chartsData[name].OfflineTrends
        });
    }
    return series;
  };

  const offlineThresholdsCanBeMapped =
    (!settings.series &&
      chartsData[name].Thresholds &&
      !isEmpty(chartsData[name].OfflineTrends)) ||
    (settings.series &&
      settings.series
        .map((seriesName) => chartsData[seriesName]?.OfflineTrends || {})
        .every((s) => isEmpty(s.values)));

  const onlineThresholdsCanBeMapped =
    (!settings.series &&
      chartsData[name].Thresholds &&
      !isEmpty(chartsData[name].OnlineTrends)) ||
    (settings.series &&
      settings.series
        .map((seriesName) => chartsData[seriesName]?.OnlineTrends || {})
        .every((s) => isEmpty(s.values)));

  chart.values = {
    offline: {
      series: createOfflineSeries(),
      thresholds: offlineThresholdsCanBeMapped
        ? mapToThresholds(chartsData, name, settings, sotType, intl)
        : null
    },
    online: {
      series: createOnlineSeries(),
      thresholds: onlineThresholdsCanBeMapped
        ? mapToThresholds(chartsData, name, settings, sotType, intl)
        : null
    }
  };

  return chart;
};

export default mapToChart;
