// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import { IssueStatuses } from "common/issueStatus/models/IssueStatuses";
import IssueCompact, {
  IIssueCompactActions
} from "features/detailpage/features/issues/components/IssueCompact";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { AppDispatch } from "store";
import { editIssueStatus, updateActiveIssue } from "../actions/IssuesActions";

export function mapDispatchToProps(
  dispatch: AppDispatch
): IIssueCompactActions {
  return {
    editIssueStatus: (
      issueId: string,
      currentStatus: IssueStatuses,
      status: IssueStatuses,
      comment: string,
      issueStatusTranslation: { [key in IssueStatuses]: string },
      intl: IntlShape,
      notifications: INotifications
    ) =>
      editIssueStatus({
        dispatch,
        issueId,
        currentStatus,
        status,
        reason: comment,
        issueStatusTranslation,
        intl,
        notifications
      }),
    updateActiveIssue: (issueId) => updateActiveIssue(dispatch, issueId)
  };
}

export default connect(undefined, mapDispatchToProps)(IssueCompact);
