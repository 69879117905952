// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch } from "store";
import { saveNotificationsSettingsAction } from "../actions/NotificationsActions";
import { INotificationsModelOutput } from "../models/INotificationsModelOutput";

const useSaveModel = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);

  const saveNotifications = useCallback(
    async (payload: INotificationsModelOutput) => {
      try {
        await saveNotificationsSettingsAction(dispatch, payload);
        notifications.success({
          message: intl.formatMessage({
            id: "notifications.navbar_container.notification_have_been_saved_successfully",
            defaultMessage:
              "Notification preferences have been saved successfully"
          })
        });
      } catch (e) {
        notifications.error({
          message: intl.formatMessage({
            id: "notifications.navbar_container.notifications_data_save_failed",
            defaultMessage: "Notifications data save failed."
          })
        });

        throw e;
      }
    },
    [dispatch, intl, notifications]
  );

  return { saveNotifications };
};

export default useSaveModel;
