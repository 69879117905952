// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import DataGrid, {
  IColumn,
  IColumnConfig,
  IDataEndpoint
} from "common/datagrid/DataGrid";
import DataService, {
  ContentTypes,
  ExportTypes
} from "common/datagrid/services/DataService";
import UrlService from "core/data/services/UrlService";
import { useCallback, useContext, useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";
import { config } from "utils/AppConfig";
import Column from "./Column";

interface ILogGridOwnProps {
  assetId: string;
  search?: string;
  logTypes?: string[];
  className?: string;
}

const LogGrid = ({
  className,
  assetId,
  search,
  logTypes
}: ILogGridOwnProps) => {
  const { notifications } = useContext(NotificationContext);

  const columns = useMemo<IColumnConfig[]>(
    () => [
      {
        id: "Date",
        component: (date: string) => (
          <Column>
            <FormattedDate
              value={new Date(date)}
              day="numeric"
              month="numeric"
              year="numeric"
              hour="numeric"
              minute="numeric"
              second="numeric"
            />
          </Column>
        ),
        message: {
          id: "detail_page.widgets.circuit_breaker.log.grid.date",
          defaultMessage: "Date"
        }
      },
      {
        id: "BoardId",
        component: (value: string) => <Column>{value}</Column>,
        message: {
          id: "detail_page.widgets.circuit_breaker.log.grid.board_id",
          defaultMessage: "Board ID"
        }
      },
      {
        id: "Type",
        component: (value: string) => <Column>{value}</Column>,
        message: {
          id: "detail_page.widgets.circuit_breaker.log.grid.type",
          defaultMessage: "Type"
        }
      },
      {
        id: "Reference",
        component: (value: string) => <Column>{value}</Column>,
        message: {
          id: "detail_page.widgets.circuit_breaker.log.grid.reference",
          defaultMessage: "Reference"
        }
      },
      {
        id: "Value",
        component: (value: string) => <Column>{value}</Column>,
        message: {
          id: "detail_page.widgets.circuit_breaker.log.grid.value",
          defaultMessage: "Value"
        }
      }
    ],
    []
  );

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.detailPage.logUrl, null, {
        assetId
      }),
      type: "POST",
      content: {
        search,
        logTypes
      }
    }),
    [assetId, logTypes, search]
  );
  const intl = useIntl();
  const handleExportLogsClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint = Object.assign({}, dataEndpoint, {
        url: UrlService.getApiUrl(config.api.detailPage.logsFile, null, {
          assetId
        })
      });
      exportDataEndpoint.content.ExportType = ExportTypes.Excel;
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Excel,
        ContentTypes.Logs,
        assetId,
        intl,
        notifications
      );
    },
    [assetId, dataEndpoint, intl, notifications]
  );

  const handleExportCsvLogsClick = useCallback(
    (columns: IColumn[]) => {
      const exportDataEndpoint = Object.assign({}, dataEndpoint, {
        url: UrlService.getApiUrl(config.api.detailPage.logsFile, null, {
          assetId
        })
      });
      exportDataEndpoint.content.ExportType = ExportTypes.Csv;
      DataService.exportData(
        exportDataEndpoint,
        columns,
        ExportTypes.Csv,
        ContentTypes.Logs,
        assetId,
        intl,
        notifications
      );
    },
    [assetId, dataEndpoint, intl, notifications]
  );

  return (
    <div className={`${className} grid-container data-grid__parent`}>
      <div className="data-grid__scroll">
        <DataGrid
          columns={columns}
          dataEndpoint={dataEndpoint}
          exportToCsv={{ disabled: false, onClick: handleExportCsvLogsClick }}
          exportToExcel={{
            disabled: false,
            onClick: handleExportLogsClick
          }}
        />
      </div>
    </div>
  );
};

const StyledLogGrid = styled(LogGrid)`
  .data-grid__scroll {
    max-height: calc(100vh - 280px);
    overflow-y: scroll;
  }
`;

export default StyledLogGrid;
