// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import {
  Button,
  Input,
  InputNumber,
  InputRef,
  Layout,
  Slider,
  Typography
} from "antd";
import CenteredWrapper from "common/CenteredWrapper";
import { ParameterService } from "common/formatters/ParameterName";
import Processing from "components/common/Processing";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import styled from "styled-components";
import { colorTeal90 } from "styles/ColorVariables";
import {
  spacingMedium,
  spacingXLarge,
  spacingXXLarge,
  spacingXXXXLarge
} from "styles/StyleVariables";
import SimulationModalContext from "../contexts/SimulationModalContext";
import useSimulationSettings from "../hooks/useSimulationSettings";
import SimulationErrors from "../models/SimulationErrors";

const { Text, Title } = Typography;
const { Footer, Content } = Layout;

interface ISimulationSettingsProps {
  className?: string;
  onCancel: () => void;
  onSuccessfulRun: (simulationId: string) => void;
}

const SimulationSettings = ({
  className,
  onCancel,
  onSuccessfulRun
}: ISimulationSettingsProps) => {
  const {
    assetId,
    modelId,
    implementationId,
    simulationConfig,
    postSimulation
  } = useContext(SimulationModalContext);
  const { notifications } = useContext(NotificationContext);

  const [processing, setProcessing] = useState<boolean>(false);

  const ref = React.createRef<InputRef>();

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const minValue = useMemo(
    () => simulationConfig.MinValue ?? 0,
    [simulationConfig.MinValue]
  );
  const maxValue = useMemo(
    () => simulationConfig.MaxValue ?? 100,
    [simulationConfig.MaxValue]
  );

  const intl = useIntl();
  const {
    inputBeginValue,
    sliderBeginValue,
    handleInputBeginChange,
    inputEndValue,
    sliderEndValue,
    handleInputEndChange,
    handleSliderChange,
    inputNameValue,
    handleInputNameChange
  } = useSimulationSettings(
    simulationConfig.DefaultBeginValue,
    simulationConfig.DefaultEndValue
  );

  const handleCancelClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleRunClick = useCallback(() => {
    setProcessing(true);
    postSimulation({
      AssetId: assetId,
      LoadParameterRange: {
        BeginValue: inputBeginValue,
        EndValue: inputEndValue
      },
      Name: inputNameValue
    }).then(
      (simulationId) => {
        onSuccessfulRun(simulationId);
      },
      (simulationError) => {
        if (simulationError === SimulationErrors.SimulationLimitExceeded) {
          notifications.error({
            message: intl.formatMessage({
              defaultMessage: "Simulation request failed. Limit exceeded.",
              id: "detail_page.risk_trend.simulation_modal.message.simulation_limit_exceeded"
            })
          });
        } else {
          notifications.error({
            message: intl.formatMessage({
              defaultMessage: "Simulation request failed",
              id: "detail_page.risk_trend.simulation_modal.message.simulation_request_failed"
            })
          });
        }
        onCancel();
      }
    );
  }, [
    assetId,
    inputBeginValue,
    inputEndValue,
    inputNameValue,
    intl,
    notifications,
    onCancel,
    onSuccessfulRun,
    postSimulation
  ]);

  return processing ? (
    <CenteredWrapper>
      <Processing className="spinner small dark" />
    </CenteredWrapper>
  ) : (
    <Layout className={className}>
      <Title level={4} className="label-title" data-qa="label">
        <FormattedMessage
          defaultMessage="New Simulation"
          id="detail_page.risk_trend.simulation_modal.label.new_simulation"
        />
      </Title>
      <Content>
        <div className="simulation-inputs">
          <div className="simulation-input">
            <Text className="simulation-input-label">
              <FormattedMessage
                defaultMessage="Name"
                id="detail_page.risk_trend.simulation_modal.label.name"
              />
            </Text>
            <Input
              type="text"
              onChange={handleInputNameChange}
              data-qa="input-name"
              maxLength={40}
              ref={ref}
            />
          </div>
          <div className="simulation-input">
            <Text className="simulation-input-label">
              <FormattedMessage
                defaultMessage="Simulation parameters{required}"
                id="detail_page.risk_trend.simulation_modal.label.simulation_parameters"
                values={{
                  required: (
                    <Text type="danger">
                      <FormattedMessage defaultMessage="*" id="global.star" />
                    </Text>
                  )
                }}
              />
            </Text>
            <div className="simulate-parameters">
              <InputNumber
                max={maxValue}
                min={minValue}
                value={inputBeginValue}
                onChange={handleInputBeginChange}
                data-qa="input-from"
              />
              <div className="simulate-slider">
                <Text data-qa="parameter-name">
                  <FormattedMessage
                    defaultMessage="{parameterName} ({unit})"
                    id="detail_page.risk_trend.simulation_modal.label.parameter_name"
                    values={{
                      parameterName: (
                        <Text strong>
                          {ParameterService.format(
                            simulationConfig.ParameterName,
                            modelId,
                            implementationId,
                            intl
                          )}
                        </Text>
                      ),
                      unit: simulationConfig.Unit
                    }}
                  />
                </Text>
                <Slider
                  defaultValue={[
                    simulationConfig.DefaultBeginValue,
                    simulationConfig.DefaultEndValue
                  ]}
                  max={maxValue}
                  min={minValue}
                  range
                  onChange={handleSliderChange}
                  value={[sliderBeginValue, sliderEndValue]}
                />
                <div className="labels">
                  <Text strong data-qa="range-start">
                    <FormattedNumber value={minValue} />
                  </Text>
                  <Text strong data-qa="range-end">
                    <FormattedNumber value={maxValue} />
                  </Text>
                </div>
              </div>
              <InputNumber
                max={maxValue}
                min={minValue}
                value={inputEndValue}
                onChange={handleInputEndChange}
                data-qa="input-to"
              />
            </div>
          </div>
        </div>
      </Content>
      <Footer>
        <Text type="danger" className="explanation">
          <FormattedMessage
            defaultMessage="{required} Required fields"
            id="detail_page.risk_trend.simulation_modal.label.required_fields"
            values={{
              required: <FormattedMessage defaultMessage="*" id="global.star" />
            }}
          />
        </Text>
        <Button data-qa="cancel-button" onClick={handleCancelClick}>
          <FormattedMessage
            defaultMessage="Cancel"
            id="detail_page.risk_trend.simulation_modal.action.cancel"
          />
        </Button>
        <Button data-qa="run-button" onClick={handleRunClick} type="primary">
          <FormattedMessage
            defaultMessage="Run"
            id="detail_page.risk_trend.simulation_modal.action.run"
          />
        </Button>
      </Footer>
    </Layout>
  );
};

const sliderMinWidth = "240px";

const StyledSimulationSettings = styled(SimulationSettings)`
  height: 100%;

  .label-title {
    padding: 0px ${spacingXLarge} ${spacingXLarge};
    color: ${colorTeal90};
  }

  .ant-layout-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .simulation-inputs {
      padding-bottom: ${spacingXXXXLarge};

      .simulation-input {
        padding-bottom: ${spacingMedium};
        margin-bottom: ${spacingXXLarge};

        .simulation-input-label {
          padding-bottom: ${spacingXLarge};
          display: block;
        }
      }

      .simulate-parameters {
        display: flex;
        align-items: center;

        .simulate-slider {
          margin-left: ${spacingXXLarge};
          margin-right: ${spacingXXLarge};
          min-width: ${sliderMinWidth};

          .labels {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .ant-layout-footer {
    display: flex;
    justify-content: flex-end;
    padding: ${spacingMedium} ${spacingXXLarge};

    .explanation {
      flex: 1;
    }

    button {
      min-width: ${spacingXXXXLarge};
    }

    button + button {
      margin-left: ${spacingMedium};
    }
  }
`;

export default StyledSimulationSettings;
