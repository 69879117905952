// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { NotificationContext } from "@pg/common/build/components/Notifications";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { saveAs } from "file-saver";
import React, { useContext } from "react";
import { useIntl } from "react-intl";

interface IExportToExcelIconProps {
  assetId: string;
  exportUrl: string;
  templateName: string;
}
export const ExportToExcelIcon = (props: IExportToExcelIconProps) => {
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);
  const handleExportToExcelIconClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const url = UrlService.getApiUrl(props.exportUrl);

    function onSuccess(xhr: JQuery.jqXHR, data: any) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      saveAs(blob, props.templateName + ".xlsx");
    }

    function onError(xhr: JQueryXHR) {
      notifications.error({
        message: intl.formatMessage(
          {
            id: "data_integration.model_config.xlsx_downloader.download_error",
            defaultMessage: "Downloading {name} failed"
          },
          { name: props.templateName }
        )
      });
    }

    EndpointService.postBinary(url, onSuccess, onError, props.assetId);
  };

  return (
    <div
      className="c-tooltip"
      data-tooltip={intl.formatMessage({
        id: "detail_page.widgets.asset_nameplate_widget.export_to_excel",
        defaultMessage: "Export to Excel"
      })}
    >
      <Icon
        dataQa="asset-export-to-excel-icon"
        name="file_download"
        onClick={handleExportToExcelIconClick}
      />
    </div>
  );
};
