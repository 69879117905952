// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Modal from "common/Modal";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import useExportModal from "../hooks/useExportModal";
import ExportModalContent from "./ExportModalContent";

interface IExportModalProps {
  className?: string;
  visible: boolean;
  closeExportModal: () => void;
  implementationId: string;
  modelId: string;
}

const ExportModal = ({
  className,
  visible,
  closeExportModal,
  implementationId,
  modelId
}: IExportModalProps) => {
  const [exportConfiguration, setExportConfiguration] =
    useState<boolean>(false);
  const [
    exportCustomInputParameterConfiguration,
    setExportCustomInputParameterConfiguration
  ] = useState<boolean>(false);
  const [exportCustomModelTranslations, setExportCustomModelTranslations] =
    useState<boolean>(false);

  const handleExportModalClose = useCallback(async () => {
    closeExportModal();
    setExportConfiguration(false);
    setExportCustomInputParameterConfiguration(false);
    setExportCustomModelTranslations(false);
  }, [closeExportModal]);

  const { onExport, intl } = useExportModal({
    modelId,
    handleExportModalClose,
    exportConfiguration,
    exportCustomInputParameterConfiguration,
    exportCustomModelTranslations
  });

  return (
    <Modal
      centered
      width={440}
      open={visible}
      okText={intl.formatMessage({
        id: "data_integration.model_config.export.modal.submit",
        defaultMessage: "Export"
      })}
      title={intl.formatMessage({
        id: "data_integration.model_config.export.modal.title",
        defaultMessage: "Export"
      })}
      destroyOnClose={true}
      onCancel={handleExportModalClose}
      onOk={onExport}
      className={className}
      data-qa="ExportModal"
      okButtonProps={{
        "data-qa": "ExportModalSubmit",
        disabled:
          !exportConfiguration &&
          !exportCustomInputParameterConfiguration &&
          !exportCustomModelTranslations
      }}
      cancelButtonProps={{
        "data-qa": "ExportModalCancel"
      }}
    >
      <ExportModalContent
        implementationId={implementationId}
        onExportConfigurationCheckboxChange={() =>
          setExportConfiguration(!exportConfiguration)
        }
        onExportCustomInputParameterConfigurationCheckboxChange={() =>
          setExportCustomInputParameterConfiguration(
            !exportCustomInputParameterConfiguration
          )
        }
        onExportCustomModelTranslationsCheckboxChange={() =>
          setExportCustomModelTranslations(!exportCustomModelTranslations)
        }
      />
    </Modal>
  );
};

const StyledExportModal = styled(ExportModal)`
  .ant-modal-header {
    background-color: ${colorPrimary};
  }

  .ant-modal-title {
    color: white;
    font-size: 13.5pt;
    font-weight: 300;
  }

  .ant-modal-close {
    color: white;
  }
`;

export default StyledExportModal;
