// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import SearchParams from "@pg/common/build/models/SearchParams";
import { Slider } from "antd";
import { SliderMarks } from "antd/lib/slider";
import ColumnAsset from "common/columns/Asset";
import ColumnRisk from "common/columns/Risk";
import Container from "common/Container";
import DataGrid, {
  IColumnConfig,
  IDataEndpoint,
  IRow
} from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { useAppNavigate } from "core/app/components/RouterProvider";
import UrlService from "core/data/services/UrlService";
import { isNil } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import styled from "styled-components";
import { spacingXLarge, spacingXXXLarge } from "styles/StyleVariables";
import { config } from "utils/AppConfig";
import AssetRiskChangesGridFooter from "./AssetRiskChangesGridFooter";
import ColumnChange from "./columns/ColumnChange";
import RiskChangeTooltip from "./RiskChangeTooltip";

const timeSpanParamName = "timeSpan";

const mapTimeSpanToText = (timeSpan: number) =>
  timeSpan === 0
    ? "day"
    : timeSpan === 1
    ? "week"
    : timeSpan === 2
    ? "month"
    : timeSpan === 3
    ? "year"
    : null;

const mapTimeSpanToNumber = (timeSpan: string) =>
  timeSpan === "day"
    ? 0
    : timeSpan === "week"
    ? 1
    : timeSpan === "month"
    ? 2
    : timeSpan === "year"
    ? 3
    : null;

interface IAssetRiskChangesGridProps {
  className?: string;
  filters: SelectedFilters;
}

const AssetRiskChangesGrid = ({
  className,
  filters
}: IAssetRiskChangesGridProps) => {
  const intl = useIntl();
  const navigate = useAppNavigate();
  const location = useLocation();
  const [rowsTotal, setRowsTotal] = useState<number>();
  const [showAll, setShowAll] = useState(false);
  const [timeSpan, setTimeSpan] = useState(() => {
    const searchParams = new SearchParams(location.search);
    const timeSpan = searchParams.get(timeSpanParamName);
    return !isNil(timeSpan) ? mapTimeSpanToNumber(timeSpan) : 1;
  });

  const columns = useMemo<IColumnConfig[]>(
    () => [
      {
        component: (value, row) => (
          <ColumnAsset
            assetId={row.data["AssetId"]}
            assetName={row.data["AssetName"]}
            assetType={row.data["AssetType"]}
            location={row.data["Location"]}
          />
        ),
        id: "Asset",
        message: {
          defaultMessage: "Asset",
          id: "asset_risk_changes.grid.columns.asset"
        },
        weight: 6
      },
      {
        component: (value, row) => (
          <ColumnRisk
            risk={row.data["PreviousRisk"]}
            Tooltip={() => (
              <RiskChangeTooltip
                level={row.data["PreviousRisk"]}
                value={row.data["PreviousScore"]}
                date={row.data["PreviousDate"]}
              />
            )}
          />
        ),
        id: "PreviousRisk",
        defaultSortOrder: SortOrders.Desc,
        defaultGroupOrder: 2,
        message: {
          defaultMessage: "Previous Risk",
          id: "asset_risk_changes.grid.columns.previous_risk"
        }
      },
      {
        component: (value, row) => (
          <ColumnChange relativeChange={row.data["ScoreChange"]} />
        ),
        id: "ScoreChange",
        message: {
          defaultMessage: "Condition change",
          id: "asset_risk_changes.grid.columns.change"
        },
        HeaderTooltip: () => (
          <div className="score-change-column-tooltip">
            <FormattedMessage
              id="asset_risk_changes.grid.columns.change.header.tooltip"
              defaultMessage="The number reflects the absolute condition change which should not be compared between asset types"
            />
          </div>
        )
      },
      {
        component: (value, row) => (
          <ColumnRisk
            risk={row.data["CurrentRisk"]}
            Tooltip={() => (
              <RiskChangeTooltip
                level={row.data["CurrentRisk"]}
                value={row.data["CurrentScore"]}
                date={row.data["CurrentDate"]}
              />
            )}
          />
        ),
        id: "AssetRisk",
        defaultSortOrder: SortOrders.Desc,
        defaultGroupOrder: 1,
        message: {
          defaultMessage: "Current Risk",
          id: "asset_risk_changes.grid.columns.current_risk"
        }
      }
    ],
    []
  );

  const dataEndpoint = useMemo<IDataEndpoint>(
    () => ({
      content: {
        search: filters.search,
        filters: [
          ...filters.selects,
          {
            Id: "RiskChangeOnlyFlag",
            Options: [{ Id: (!showAll).toString() }]
          },
          {
            Id: "RiskChangeTimespan",
            Options: [
              {
                Id: mapTimeSpanToText(timeSpan)
              }
            ]
          }
        ]
      },
      onDataLoaded: setRowsTotal,
      type: "POST",
      url: UrlService.getApiUrl(
        config.api.assetRiskChanges.assetRiskChangesRange
      )
    }),
    [filters, showAll, timeSpan]
  );

  const marks = useMemo<SliderMarks>(
    () => ({
      0: intl.formatMessage({
        defaultMessage: "1 day",
        id: "asset_risk_changes.slider.1_day"
      }),
      1: intl.formatMessage({
        defaultMessage: "1 week",
        id: "asset_risk_changes.slider.1_week"
      }),
      2: intl.formatMessage({
        defaultMessage: "1 month",
        id: "asset_risk_changes.slider.1_month"
      }),
      3: intl.formatMessage({
        defaultMessage: "1 year",
        id: "asset_risk_changes.slider.1_year"
      })
    }),
    [intl]
  );

  const handleTimeSpanChange = useCallback(
    (value: number) => {
      setTimeSpan(value);

      const searchParams = new SearchParams(location.search);
      searchParams.set(timeSpanParamName, mapTimeSpanToText(value));
      navigate(
        {
          search: searchParams.toString()
        },
        { replace: true }
      );
    },
    [location.search, navigate]
  );

  const handleShowAllClick = useCallback((value: boolean) => {
    setShowAll(value);
  }, []);

  return (
    <div className={`${className} data-grid__parent`}>
      <div className="data-grid__scroll">
        <Container>
          <div className="header">
            <div className="title">
              {!isNil(rowsTotal) ? (
                <SectionName
                  messageDefault="Asset risk changes / {numberOfAssetRiskChanges, number} items"
                  messageId="asset_risk_changes.header.asset_risk_changes_total"
                  messageValues={{
                    numberOfAssetRiskChanges: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Asset risk changes"
                  messageId="asset_risk_changes.header.asset_risk_changes"
                />
              )}
              <StatusBar />
            </div>
            <Slider
              className="time-range-slider"
              value={timeSpan}
              marks={marks}
              max={3}
              onChange={handleTimeSpanChange}
              step={null}
              tooltip={{
                open: false
              }}
            />
          </div>
          <DataGrid
            columns={columns}
            dataEndpoint={dataEndpoint}
            footerComponent={(rows: IRow[], rowsTotal: number) => (
              <AssetRiskChangesGridFooter
                showAll={showAll}
                onShowAllClick={handleShowAllClick}
                rowsTotal={rowsTotal}
              />
            )}
          />
        </Container>
      </div>
    </div>
  );
};

const StyledAssetRiskChangesGrid = styled(AssetRiskChangesGrid)`
  overflow: hidden;
  height: 100%;
  & {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        > *:first-child {
          margin-right: ${spacingXLarge};
        }
      }

      .time-range-slider {
        margin-left: ${spacingXXXLarge};
        margin-right: ${spacingXXXLarge};
        min-width: 280px;
        width: 280px;
        white-space: nowrap;
      }
    }
  }
  .data-grid__scroll {
    height: 100%;
  }
`;

export default StyledAssetRiskChangesGrid;
