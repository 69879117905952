// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Typography } from "antd";
import { Statuses } from "common/ChartLegend/hooks/useLegend";
import ILTCOperationsChartLegendItem from "features/detailpage/features/ltc/models/ILTCOperationsChartLegendItem";
import { kebabCase } from "lodash";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  fontSizeSmall,
  spacingBase,
  spacingLarge,
  spacingXXLarge
} from "styles/StyleVariables";

const { Text } = Typography;

interface ILTCOperationsBarChartLegendItemProps {
  item: ILTCOperationsChartLegendItem;
  status: Statuses;
  className?: string;
}

const LTCOperationsBarChartLegendItem = ({
  item,
  status,
  className
}: ILTCOperationsBarChartLegendItemProps) => {
  return (
    <div
      className={`${className} ${
        Statuses[status] && Statuses[status].toLowerCase()
      }`}
    >
      <div className="item-box">
        <div
          className="item-box-background"
          style={{ backgroundColor: item.color }}
        />
        {item.isGridLine && <div className="grid-line" />}
      </div>
      <Text type="secondary" className="item-text">
        <FormattedMessage
          defaultMessage={item.name}
          id={`detail_page.widgets.analytics.transformers.ltc.ltc_operations_chart_legend.${kebabCase(
            item.name
          )}`}
        />
      </Text>
    </div>
  );
};

export default styled(LTCOperationsBarChartLegendItem)`
  cursor: default;
  display: flex;
  align-items: center;

  .item-box {
    display: flex;
    align-items: center;
    width: ${spacingXXLarge};
    height: ${spacingLarge};
    border-radius: 4px;
    margin-right: ${spacingBase};
    position: relative;

    &-background {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:has(.grid-line) {
      .item-box-background {
        opacity: 0.2;
      }
    }

    .grid-line {
      width: 100%;
      border-top: 2px dashed black;
      border-color: ${(props) => props.item.color};
      height: 0;
      width: 5ch;
      border-style: dashed;
      border-width: 2px;
    }
  }
  .item-text {
    font-size: ${fontSizeSmall};
    font-weight: 500;
  }
`;
