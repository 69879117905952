// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { NotificationContext } from "@pg/common/build/components/Notifications";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch } from "store";
import styled from "styled-components";
import { colorIconAccent } from "styles/ColorVariables";
import { deleteAsset } from "./actions/DeleteAssetAction";

interface IEditDeleteAssetActionsProps {
  assetId: string;
  onAssetModalOpen: (assetId: string) => void;
  className?: string;
}

const EditDeleteAssetActions = ({
  assetId,
  onAssetModalOpen,
  className
}: IEditDeleteAssetActionsProps) => {
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);
  const dispatch = useAppDispatch();

  const handleDeleteAssetConfirm = useCallback(
    async (assetId: string) => {
      try {
        await deleteAsset(dispatch, assetId);
        notifications.success({
          message: intl.formatMessage({
            id: "asset_query.grid.close.success",
            defaultMessage: "Asset was deleted"
          })
        });
      } catch (e) {
        notifications.success({
          message: intl.formatMessage(
            {
              id: "asset_query.grid.close.success",
              defaultMessage: "Cannot delete asset. Response code: {code}"
            },
            {
              code: e.status
            }
          )
        });
      }
    },
    [dispatch, intl, notifications]
  );

  const handleDeleteAsset = useCallback(
    (assetId: string) => {
      notifications.confirm({
        title: intl.formatMessage({
          defaultMessage: "Are you sure you want to delete this asset?",
          id: "asset_query.grid.opened_actions_confirmation"
        }),
        onOk: () => {
          handleDeleteAssetConfirm(assetId);
        }
      });
    },
    [handleDeleteAssetConfirm, intl, notifications]
  );

  return (
    <div
      className={`${className} manage-assets-actions`}
      data-qa={`manage-assets-actions`}
    >
      <Icon
        name="border_color"
        onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
          onAssetModalOpen(assetId);
          e.stopPropagation();
        }}
        size="xs"
        variant="outlined"
      />
      <Icon
        name="delete"
        size="xs"
        variant="outlined"
        onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
          handleDeleteAsset(assetId);
          e.stopPropagation();
        }}
      />
    </div>
  );
};

const StyledEditDeleteAssetActions = styled(EditDeleteAssetActions)`
  display: flex;
  flex-direction: column;

  .icon {
    &:hover {
      color: ${colorIconAccent};
    }
  }
`;

export default StyledEditDeleteAssetActions;
