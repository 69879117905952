// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { OilTest } from "../models/IOilSettings";

const oilUnits: { [key in OilTest]?: string } = {
  AcidNumber: "mg KOH/g",
  Color: "",
  DielectricStrength: "kV",
  InterfacialTension: "mN/m",
  WaterContent: "mg/kg",
  PowerFactor90C: "",
  PowerFactor25C: "%",
  PowerFactor100C: "%",
  Moisture: "ppm",
  InhibitorContent: "%",
  DissipationFactor: "%"
};

export default oilUnits;
