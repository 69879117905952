// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { Checkbox, Space } from "antd";
import { FormattedMessage } from "react-intl";
import { styled } from "styled-components";
import { colorGray10, colorGray70 } from "styles/ColorVariables";
import { fontSizeSmall } from "styles/StyleVariables";

interface IExportModalContentProps {
  implementationId: string;
  onExportConfigurationCheckboxChange: () => void;
  onExportCustomInputParameterConfigurationCheckboxChange: () => void;
  onExportCustomModelTranslationsCheckboxChange: () => void;
}

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: ${colorGray70};
  background-color: ${colorGray10};
  font-size: ${fontSizeSmall};

  > span + span {
    margin-left: 10px;
  }
`;

const ExportModalContent = ({
  implementationId,
  onExportConfigurationCheckboxChange,
  onExportCustomInputParameterConfigurationCheckboxChange,
  onExportCustomModelTranslationsCheckboxChange
}: IExportModalContentProps) => {
  const transformers = [
    "AbbTransformerStandardModelV6",
    "AbbTransformerHVDCModelV6"
  ];

  return (
    <Space direction="vertical" size="small">
      <Checkbox
        onChange={onExportConfigurationCheckboxChange}
        data-qa="StandardConfiguration"
      >
        <FormattedMessage
          defaultMessage="Standard model configuration"
          id="data_integration.model_config.export.modal.checkbox.standard_model_configuration"
        />
      </Checkbox>
      <Checkbox
        onChange={onExportCustomInputParameterConfigurationCheckboxChange}
        data-qa="CustomInputConfiguration"
      >
        <FormattedMessage
          defaultMessage="Custom input parameter configuration mapping"
          id="data_integration.model_config.export.modal.checkbox.custom_input_parameter_configuration"
        />
      </Checkbox>
      <Checkbox
        onChange={onExportCustomModelTranslationsCheckboxChange}
        data-qa="CustomModelTranslations"
      >
        <FormattedMessage
          defaultMessage="Custom model translations"
          id="data_integration.model_config.export.modal.checkbox.custom_model_translations"
        />
      </Checkbox>
      {transformers.includes(implementationId) ? (
        <Message>
          <Icon name="info" variant="outlined" />
          <FormattedMessage
            id="data_integration.model_config.export.modal.sensitive_data_message"
            defaultMessage="Sensitive model data, such as license information will not be exported."
          />
        </Message>
      ) : null}
    </Space>
  );
};

export default ExportModalContent;
