// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Col, Row } from "antd";
import DataSource from "common/DuvalAnalysis/models/DataSource";
import ResizeWrapper from "common/ResizeWrapper";
import gasLabels from "common/labels/gasLabels";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useCallback } from "react";
import { IntlShape, injectIntl, useIntl } from "react-intl";
import { IData } from "utils/WebService";
import { DuvalDataPoint, DuvalDataType } from "./DuvalTriangle";
import DuvalTriangleChart from "./DuvalTriangleChart";
import DuvalTriangleType3Note from "./DuvalTriangleType3Note";
import DuvalTriangleType4Note from "./DuvalTriangleType4Note";
import DuvalTriangleType5Note from "./DuvalTriangleType5Note";
import EDuvalType from "./models/EDuvalType";
import EFluidType from "./models/EFluidType";
import EGasStandard from "./models/EGasStandard";
import ERevision from "./models/ERevision";
import IDuvalRegion from "./models/IDuvalRegion";
import IDuvalTabConfiguration from "./models/IDuvalTabConfiguration";

export interface ITriangleData {
  fluidType: EFluidType;
  gasStandard: EGasStandard;
  revision: ERevision;
  type1: IDuvalPoint[];
  type3: IDuvalPoint[];
  type4: IDuvalPoint[];
  type5: IDuvalPoint[];
}

interface IDuvalPoint {
  date: Date;
  left: number;
  right: number;
  base: number;
}

interface IDuvalTrianglesTabProps {
  dataSource: DataSource;
  intl: IntlShape;
  duvalConfiguration: IDuvalTabConfiguration;
  triangleData: IData<ITriangleData>;
  isDuvalType3HasNote: boolean;
}

const DuvalTrianglesTab = ({
  dataSource,
  duvalConfiguration,
  triangleData,
  isDuvalType3HasNote
}: IDuvalTrianglesTabProps) => {
  const intl = useIntl();

  const duvalConfigurationExists = useCallback(
    (configuration: IDuvalRegion[]) => configuration?.length > 0,
    []
  );

  const convertToDuvalDataPoints = useCallback(
    (points: IDuvalPoint[]): DuvalDataPoint[] =>
      (points || []).map((p) =>
        p ? new DuvalDataPoint(p.left, p.right, p.base, p.date) : null
      ),
    []
  );

  const showDuvalTriangle = useCallback(
    (
      duvalType: EDuvalType,
      duvalConfiguration: IDuvalTabConfiguration
    ): boolean => {
      switch (duvalType) {
        case EDuvalType.Type1:
          return duvalConfigurationExists(duvalConfiguration?.type1);
        case EDuvalType.Type3:
          return duvalConfigurationExists(duvalConfiguration?.type3);
        case EDuvalType.Type4:
          return duvalConfigurationExists(duvalConfiguration?.type4);
        case EDuvalType.Type5:
          return duvalConfigurationExists(duvalConfiguration?.type5);
      }
    },
    [duvalConfigurationExists]
  );

  const getAnalyticsWidget = useCallback(
    (): JSX.Element => (
      <Row>
        {showDuvalTriangle(EDuvalType.Type1, duvalConfiguration) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={dataSource}
                points={convertToDuvalDataPoints(triangleData.data.type1)}
                type={DuvalDataType.Type1}
                regions={duvalConfiguration.type1}
                labels={{ left: gasLabels.CH4, right: gasLabels.C2H4, base: gasLabels.C2H2 }}
              />
            </ResizeWrapper>
          </Col>
        )}
        {showDuvalTriangle(EDuvalType.Type3, duvalConfiguration) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={dataSource}
                points={convertToDuvalDataPoints(triangleData.data.type3)}
                type={DuvalDataType.Type3}
                regions={duvalConfiguration.type3}
                labels={{ left: gasLabels.CH4, right: gasLabels.C2H4, base: gasLabels.C2H2 }}
                note={isDuvalType3HasNote && <DuvalTriangleType3Note />}
              />
            </ResizeWrapper>
          </Col>
        )}
        {showDuvalTriangle(EDuvalType.Type4, duvalConfiguration) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={dataSource}
                points={convertToDuvalDataPoints(triangleData.data.type4)}
                type={DuvalDataType.Type4}
                regions={duvalConfiguration.type4}
                note={<DuvalTriangleType4Note />}
                labels={{ left: gasLabels.H2, right: gasLabels.CH4, base: gasLabels.C2H6 }}
              />
            </ResizeWrapper>
          </Col>
        )}
        {showDuvalTriangle(EDuvalType.Type5, duvalConfiguration) && (
          <Col span={8}>
            <ResizeWrapper>
              <DuvalTriangleChart
                dataSource={dataSource}
                points={convertToDuvalDataPoints(triangleData.data.type5)}
                type={DuvalDataType.Type5}
                regions={duvalConfiguration.type5}
                note={<DuvalTriangleType5Note />}
                labels={{ left: gasLabels.CH4, right: gasLabels.C2H4, base: gasLabels.C2H6 }}
              />
            </ResizeWrapper>
          </Col>
        )}
      </Row>
    ),
    [
      convertToDuvalDataPoints,
      dataSource,
      duvalConfiguration,
      isDuvalType3HasNote,
      showDuvalTriangle,
      triangleData
    ]
  );

  const getComponentByStatus = useCallback((): JSX.Element => {
    switch (triangleData.status) {
      case Statuses.Loading:
        return <Processing />;
      case Statuses.NotFound:
        return triangleData.message ? (
          <WidgetErrorMessage
            messageId={`detail_page.widgets.analytics.transformers.error.${triangleData.message}`}
            messageDefault={intl.formatMessage({
              id: "detail_page.widgets.analytics.transformers.error.visualization_is_not_available",
              defaultMessage: "The visualization is not available"
            })}
          />
        ) : (
          <WidgetNoDataMessage />
        );
      case Statuses.Succeeded:
        return getAnalyticsWidget();
      default:
        return (
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={""}
            messageValues={""}
          />
        );
    }
  }, [getAnalyticsWidget, intl, triangleData]);

  return (
    <div className="analytics duval-triangles" data-qa="duval-triangles">
      {getComponentByStatus()}
    </div>
  );
};

export default injectIntl(DuvalTrianglesTab, {
  forwardRef: true
});
