// Copyright 2016-2024 Hitachi Energy. All rights reserved.

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@pg/common";
import Tooltip from "common/Tooltip";
import useNavToAsset from "hooks/useNavToAsset";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import {
  spacingBase,
  spacingLarge,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";
import IssuesListAssetInfoIssues from "../components/IssuesListAssetInfoIssues";
import IAssetWithIssues from "../models/AssetWithIssues";

const TOGGLE_ICON_WIDTH = "24px";

interface IIssuesListAssetInfoProps {
  asset: IAssetWithIssues;
  className?: string;
}

const IssuesListAssetInfo = ({
  asset,
  className = ""
}: IIssuesListAssetInfoProps) => {
  const [issueListVisible, setIssueListVisible] = useState(true);

  const { navigateTo } = useNavToAsset();

  const Text = (
    <>{asset.Name ? `${asset.AssetId} - ${asset.Name}` : asset.AssetId}</>
  );

  return (
    <div
      className={`${className} ${!issueListVisible && "list-item-collapsed"}`}
      onClick={() => setIssueListVisible((isVisible) => !isVisible)}
    >
      <Wrapper>
        <Info>
          <Tooltip title={<div>{Text}</div>}>
            <InfoLink
              data-qa="link"
              className="link-label"
              to={navigateTo(asset.AssetId)}
            >
              {Text}
            </InfoLink>
          </Tooltip>
          {!issueListVisible && (
            <span data-qa="asset-issues-count">
              <FormattedMessage
                defaultMessage="{ count, plural, zero { no issues } one { # issue } other { # issues } }"
                id="locations_page.side_panel.issues.issues_count"
                values={{
                  count: asset.Issues.length
                }}
              />
            </span>
          )}
        </Info>
        <ToggleIconWrapper data-qa="toggle-icon">
          <ToggleIcon name={issueListVisible ? "expand_less" : "expand_more"} />
        </ToggleIconWrapper>
      </Wrapper>
      {issueListVisible && <IssuesListAssetInfoIssues issues={asset.Issues} />}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacingLarge} 0;
`;

const ToggleIconWrapper = styled.div`
  display: flex;
`;

const ToggleIcon = styled(Icon)`
  width: ${TOGGLE_ICON_WIDTH};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacingBase};
  width: calc(100% - ${TOGGLE_ICON_WIDTH});
`;

const InfoLink = styled(Link)`
  &,
  &:hover {
    font-size: ${spacingXLarge};
    color: ${colorPrimary};
    padding-bottom: ${spacingXSmall};
  }
`;

export default styled(IssuesListAssetInfo)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.list-item-collapsed {
    cursor: pointer;
  }
`;
