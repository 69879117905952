// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import TileErrorMessage from "common/tile/TileErrorMessage";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { config } from "utils/AppConfig";
import DropDown from "../../components/DropDown";
import Footer from "./Footer";
import List from "./List";

const WatchList = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);

  const changeHandler = useCallback(
    (isOpened: boolean) => {
      if (isOpened && data.length === 0) {
        setIsLoading(true);

        const url = UrlService.getApiUrl(config.api.homepage.watchList);

        EndpointService.getJson(
          url,
          (response) => {
            setData(response.responseJSON);
            setIsLoading(false);
            setIsError(false);
          },
          () => {
            setIsError(true);
            setIsLoading(false);
          }
        );
      }
    },
    [data.length]
  );

  return (
    <div className="tile" data-qa="watchlist-tile">
      <DropDown
        isOpenedByDefault
        maxHeight={300}
        title={intl.formatMessage({
          defaultMessage: "Watch List",
          id: "homepage.watch_list.title"
        })}
        storageKey="watch-list"
        Footer={<Footer />}
        changeHandler={changeHandler}
      >
        {isError && (
          <ErrorMessage
            messageId="homepage.server_error"
            defaultMessage="Sorry, we have problems with the server connection."
          />
        )}
        {!isError && <List data={data} isLoading={isLoading} />}
      </DropDown>
    </div>
  );
};

const ErrorMessage = styled(TileErrorMessage)`
  border: none;
  margin: 0;

  & > .tile-content {
    padding: 0;
  }
`;

export default WatchList;
