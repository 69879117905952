// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import INotifications from "@pg/common/build/components/Notifications/models/INotifications";
import { switchIssueModeToEdit } from "features/detailpage/features/issues/actions/IssuesActions";
import Actions, {
  IActionsActions,
  IActionsData,
  IActionsOwnProps
} from "features/detailpage/features/issues/components/Actions";
import { IntlShape, MessageDescriptor } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { AppDispatch } from "store";
import modalWindowStore from "store/common/modal/ModalWindowStore";
import { loadWorkOrders } from "../actions/ActionsActions";

export function mapStateToProps(state: IState): IActionsData {
  return {
    availableWorkOrders: state.detailPage.actions.workOrders
  };
}

export function mapDispatchToProps(
  dispatch: AppDispatch,
  ownProps: IActionsOwnProps
): IActionsActions {
  return {
    closeModal: (modalId: string) => {
      modalWindowStore.closeModal(modalId);
    },
    loadWorkOrders: (): Promise<{}> =>
      loadWorkOrders(dispatch, ownProps.assetId),
    openModal: (modalId: string) => {
      modalWindowStore.openModal(modalId);
    },
    showErrorMessage: (
      intl: IntlShape,
      notifications: INotifications,
      message: MessageDescriptor
    ) => {
      notifications.error({
        message: intl.formatMessage({
          id: message.id.toString(),
          defaultMessage: message.defaultMessage
        })
      });
    },
    switchIssueModeToEdit: (issueId: string) => {
      switchIssueModeToEdit(dispatch, issueId);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
