// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import NotificationsService from "core/app/services/NotificationsService";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IMessages } from "features/detailpage/features/messages/reducers/MessagesReducer";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { AppDispatch } from "store";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_Messages_SetMessages = "DetailPage_Messages_SetMessages"
}

export const setMessagesAction = (
  request: JQuery.jqXHR,
  data?: IMessages
): IAjaxAction<IMessages> => ({
  type: Actions.DetailPage_Messages_SetMessages,
  request,
  data
});

export const loadMessages = (
  dispatch: AppDispatch,
  intl: IntlShape,
  notifications: INotifications,
  assetId: string
) => {
  const url = UrlService.getApiUrl(config.api.detailPage.messages.messagesUrl, [
    {
      name: "assetId",
      value: assetId
    }
  ]);

  const request = EndpointService.getJson<IMessages>(
    url,
    (request, data) => {
      dispatch(setMessagesAction(request, data));
    },
    (request) => {
      dispatch(setMessagesAction(request));
      NotificationsService.notifyIfRequestFailed(intl, notifications, request, {
        id: "detail_page.messages.loading_failed",
        defaultMessage: "Cannot load messages data. Response code: {code}"
      });
    }
  );

  dispatch(setMessagesAction(request));
};
