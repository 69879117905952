// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const isCondition = (key: string, n: string) =>
  key.toLowerCase().endsWith(`condition ${n}`) ||
  key.toLowerCase().endsWith(`condition ${n} online`);

export const isCondition1 = (key: string) => isCondition(key, "1");
export const isCondition2 = (key: string) => isCondition(key, "2");
export const isCondition3 = (key: string) => isCondition(key, "3");
export const isCondition4 = (key: string) => isCondition(key, "4");

const getConditionN = (key: string) =>
  isCondition1(key)
    ? 1
    : isCondition2(key)
    ? 2
    : isCondition3(key)
    ? 3
    : isCondition4(key)
    ? 4
    : undefined;

export default getConditionN;
