// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IChart } from "@apm/widgets/build/widgets/ParameterCharts";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { colorBlue60, colorStatusOrange } from "styles/ColorVariables";
import chartSettings from "../constants/chartSettings";
import { OilTest } from "../models/IOilSettings";
import { IChartsCollection } from "../models/ISOTData";
import { PowerFactorType } from "../models/PowerFactorTypeEnums";
import SotTypes from "../models/SotTypes";
import mapToChart from "../utils/mapToChart";

const useSOT = (
  assetId: string,
  loadSOTData: (assetId: string) => void,
  removeSOTData: (assetId: string) => void,
  chartsData: IChartsCollection,
  sotType: SotTypes
) => {
  const intl = useIntl();

  const oils = useMemo<IChart[]>(() => {
    if (!chartsData) return;

    return Object.entries(chartSettings[sotType])
      .map(([oilTest, oilSettings]) =>
        mapToChart(chartsData, oilTest as OilTest, oilSettings, sotType, intl)
      )
      .filter((p) => p.values);
  }, [chartsData, intl, sotType]);

  const getSeriesColor = useCallback(
    (seriesName: string) => {
      if (seriesName.includes("online")) return colorBlue60;

      if (
        sotType === SotTypes.IEEE ||
        sotType === SotTypes.MINERAL_OIL_IEEE_NETWORK_TRANSFORMER
      ) {
        switch (seriesName) {
          case `${PowerFactorType.PF100}_offline`:
          case PowerFactorType.PF100:
            return colorBlue60;
          case `${PowerFactorType.PF25}_offline`:
          case PowerFactorType.PF25:
            return colorStatusOrange;
          default:
            return "black";
        }
      }

      return "black";
    },
    [sotType]
  );

  useEffect(() => {
    loadSOTData(assetId);
    return () => {
      removeSOTData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    oils,
    getSeriesColor,
    sotType
  };
};

export default useSOT;
