// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Legend from "@apm/widgets/build/models/Legend";
import {
  colorBlue60,
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import SotTypes from "../models/SotTypes";

const formatSotTypeLegendName = (sotType: SotTypes) => {
  switch (sotType) {
    case SotTypes.MINERAL_OIL_IEEE_NETWORK_TRANSFORMER:
      return "Mineral Oil IEEE";
    case SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER:
      return "Mineral Oil IEC";
    case SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER:
      return "Silicone IEEE";
    case SotTypes.IEEE:
      return "Transformer IEEE";
    case SotTypes.IEC:
      return "Transformer IEC";
  }
};

const getSotTypeTranslation = (intl: IntlShape, sotType: SotTypes) => {
  return intl.formatMessage(
    {
      defaultMessage: "{sotType}",
      id: `detail_page.widgets.analytics.transformers.sot.type.${sotType}`
    },
    {
      sotType: formatSotTypeLegendName(sotType)
    }
  );
};

const getLegend =
  (sotType: SotTypes, intl: IntlShape) =>
  (_chartGroupName: string, dataSource: string) => {
    const legend: Legend = [];
    legend.push({
      name: sotType,
      displayName: getSotTypeTranslation(intl, sotType),
      isDataSeries: false,
      type: "info"
    });

    const showPowerFactor =
      sotType === SotTypes.IEEE ||
      sotType === SotTypes.MINERAL_OIL_IEEE_NETWORK_TRANSFORMER;
    const showAlarmAlert =
      sotType === SotTypes.SILICONE_IEEE_NETWORK_TRANSFORMER;
    const showPoorFair =
      sotType === SotTypes.IEC ||
      sotType === SotTypes.MINERAL_OIL_IEC_NETWORK_TRANSFORMER;

    if (dataSource === "offline") {
      legend.push({
        name: "offline_data",
        displayName: intl.formatMessage({
          defaultMessage: "Offline data",
          id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.offline_data`
        }),
        isDataSeries: false,
        type: "square",
        color: "black"
      });
    }

    if (dataSource === "online") {
      legend.push({
        name: "online_data",
        displayName: intl.formatMessage({
          defaultMessage: "Online data",
          id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.online_data`
        }),
        isDataSeries: false,
        type: "square",
        color: colorBlue60
      });
    }

    if (showPowerFactor) {
      legend.push({
        name: "PowerFactor100C_offline",
        displayName: intl.formatMessage({
          defaultMessage: "PF 100 (°C)",
          id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.PowerFactor100C_offline`
        }),
        isDataSeries: true,
        type: "square",
        color: colorBlue60
      });

      legend.push({
        name: "PowerFactor25C_offline",
        displayName: intl.formatMessage({
          defaultMessage: "PF 25 (°C)",
          id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.PowerFactor25C_offline`
        }),
        isDataSeries: true,
        type: "square",
        color: colorStatusOrange
      });
    }

    if (showAlarmAlert) {
      legend.push(
        {
          name: "Alarm",
          displayName: intl.formatMessage({
            defaultMessage: "Alarm",
            id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.Alarm`
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusRed
        },
        {
          name: "Alert",
          displayName: intl.formatMessage({
            defaultMessage: "Alert",
            id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.Alert`
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusOrange
        }
      );
    }

    if (showPoorFair) {
      legend.push(
        {
          name: "Poor",
          displayName: intl.formatMessage({
            defaultMessage: "Poor",
            id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.Poor`
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusRed
        },
        {
          name: "Fair",
          displayName: intl.formatMessage({
            defaultMessage: "Fair",
            id: `detail_page.widgets.analytics.transformers.sot.legend.${sotType}.Fair`
          }),
          isDataSeries: false,
          type: "region",
          color: colorStatusOrange
        }
      );
    }
    return legend;
  };

export default getLegend;
