// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { saveAs } from "file-saver";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";

const useExportToCsv = () => {
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);
  const handleExportCsvClick = useCallback(
    (assetId: string) => {
      const url = UrlService.getApiUrl(
        config.api.detailPage.assetParametersCsvExportUrl,
        { assetId }
      );

      const templateName = `${assetId}_ParametersHistory`;
      EndpointService.postBinary<Blob>(
        url,
        (request, data) => {
          const blob = new Blob([data], {
            type: "application/csv"
          });
          saveAs(blob, templateName + ".csv");
        },
        () => {
          notifications.error({
            message: intl.formatMessage(
              {
                id: "data_grid.footer.export.download.error",
                defaultMessage: "Downloading {name} failed"
              },
              { name: templateName }
            )
          });
        },
        assetId
      );
    },
    [intl, notifications]
  );

  return {
    handleExportCsvClick
  };
};

export default useExportToCsv;
