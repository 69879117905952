// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import Data, { Statuses } from "core/data/models/Data";
import { IntlShape, MessageDescriptor } from "react-intl";

export default class NotificationsService {
  static notifyIfRequestFailed(
    intl: IntlShape,
    notifications: INotifications,
    request: JQuery.jqXHR<any>,
    message?: MessageDescriptor
  ) {
    const status = Data.mapHttpStatusToStatus(request.status);
    if (status === Statuses.Failed) {
      notifications.error({
        message: intl.formatMessage(
          {
            id: message.id.toString() || "core.notifications.request_failed",
            defaultMessage:
              message.defaultMessage ||
              "It is not possible to load the data. Response code: {code}"
          },
          { code: request.status }
        )
      });
    }
  }
}
