// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import {
  colorStatusGreen,
  colorStatusOrange,
  colorStatusRed,
  colorStatusYellow
} from "@pg/common/build/styles/ColorVariables";
import {
  isCondition1,
  isCondition2,
  isCondition3,
  isCondition4
} from "./getConditionN";

const isRatioThreshold = (key: string, level: "low" | "high") =>
  key.toLowerCase().endsWith(`ratio ${level} threshold`) ||
  key.toLowerCase().endsWith(`ratio ${level} threshold online`);

const getThresholdColor = (
  name: string,
  gasStandard?: string,
  gasStandardRevision?: string
) => {
  const isIEEE2019 =
    gasStandard === "IEEE" && gasStandardRevision?.indexOf("2019") >= 0;

  return isCondition1(name)
    ? colorStatusGreen
    : isCondition2(name)
    ? colorStatusYellow
    : isCondition3(name) && isIEEE2019
    ? colorStatusRed
    : isCondition3(name)
    ? colorStatusOrange
    : isCondition4(name)
    ? colorStatusRed
    : isRatioThreshold(name, "low")
    ? colorStatusOrange
    : isRatioThreshold(name, "high")
    ? colorStatusOrange
    : undefined;
};

export default getThresholdColor;
