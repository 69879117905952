// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Icon } from "@pg/common";
import { NotificationContext } from "@pg/common/build/components/Notifications";
import { colorStatusRed } from "@pg/common/build/styles/ColorVariables";
import { Tooltip, Typography } from "antd";
import addToWatchList from "common/watchIcon/actions/addToWatchList";
import removeFromWatchList from "common/watchIcon/actions/removeFromWatchList";
import IWatchIconTranslations from "common/watchIcon/models/IWatchIconTranslations";
import { useCallback, useContext, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import styled from "styled-components";
import { colorTeal90 } from "styles/ColorVariables";
import useShowTextForNameplateIcon from "../../hooks/useShowTextForNameplateIcon";

const { Text } = Typography;

interface IIconSettings {
  text: string;
  iconVariant: string;
}

interface IWatchIconProps {
  assetId: string;
  assetWatched: boolean;
  limitReached: boolean;
  translations: IWatchIconTranslations;
  onAddAssetToWatchList: () => void;
  onRemoveAssetFromWatchList: () => void;
  getAddErrorMessage: (request: JQuery.jqXHR) => string;
  getRemoveErrorMessage: (request: JQuery.jqXHR) => string;
  showError: boolean;
}

const WatchIcon = ({
  assetId,
  assetWatched,
  limitReached,
  translations,
  onAddAssetToWatchList,
  onRemoveAssetFromWatchList,
  getAddErrorMessage,
  getRemoveErrorMessage,
  showError
}: IWatchIconProps) => {
  const [watched, setWatched] = useState(assetWatched);
  const [processing, setProcessing] = useState(false);
  const showTextForIcon = useShowTextForNameplateIcon();

  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);

  const getInternalErrorMessage = useCallback(
    (intl: IntlShape) =>
      intl.formatMessage({
        id: `widget.messages.error`,
        defaultMessage: "Internal error"
      }),
    []
  );

  const handleIconClick = useCallback(() => {
    if (watched) {
      setProcessing(true);
      removeFromWatchList(assetId)
        .then(() => {
          setWatched(false);
          onRemoveAssetFromWatchList();
          notifications.success({
            message: translations.assetRemoved
          });
        })
        .catch((request: JQuery.jqXHR) => {
          const message = getRemoveErrorMessage(request);
          notifications.error({
            message
          });
        })
        .finally(() => {
          setProcessing(false);
        });
    } else if (!limitReached) {
      setProcessing(true);
      addToWatchList(assetId)
        .then(() => {
          setWatched(true);
          onAddAssetToWatchList();
          notifications.success({
            message: translations.assetAdded
          });
        })
        .catch((request: JQuery.jqXHR) => {
          const message = getAddErrorMessage(request);
          notifications.error({
            message
          });
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [
    watched,
    limitReached,
    assetId,
    onRemoveAssetFromWatchList,
    notifications,
    translations.assetRemoved,
    translations.assetAdded,
    getRemoveErrorMessage,
    onAddAssetToWatchList,
    getAddErrorMessage
  ]);

  const iconSettings: IIconSettings = useMemo(
    () =>
      watched
        ? {
            text: translations.stopWatching,
            iconVariant: "round"
          }
        : {
            text: translations.startWatching,
            iconVariant: "outlined"
          },
    [watched, translations.stopWatching, translations.startWatching]
  );

  const watchIconButton = useMemo(
    () => (
      <StyledAssetWatchIconButton
        className="ant-btn ant-btn-link button-with-icon"
        onClick={showError ? null : handleIconClick}
        $actionDisabled={processing}
        $showTextForIcon={showTextForIcon}
        $fullList={limitReached && !watched}
      >
        <Icon
          name="grade"
          variant={showError ? "outlined" : iconSettings.iconVariant}
          className={`star-icon ${showError ? "error" : ""}`}
        />
        {iconSettings.text && showTextForIcon && !showError && (
          <Text>{iconSettings.text}</Text>
        )}
      </StyledAssetWatchIconButton>
    ),
    [
      showError,
      handleIconClick,
      iconSettings.iconVariant,
      iconSettings.text,
      limitReached,
      processing,
      showTextForIcon,
      watched
    ]
  );

  const watchIcon = useMemo(() => {
    if (showError)
      return (
        <Tooltip placement="top" title={getInternalErrorMessage(intl)}>
          {watchIconButton}
        </Tooltip>
      );

    if (limitReached && !watched)
      return (
        <Tooltip placement="top" title={translations.watchListIsFull}>
          {watchIconButton}
        </Tooltip>
      );

    if (!showTextForIcon)
      return (
        <Tooltip placement="top" title={iconSettings.text}>
          {watchIconButton}
        </Tooltip>
      );

    return watchIconButton;
  }, [
    showError,
    getInternalErrorMessage,
    intl,
    watchIconButton,
    limitReached,
    watched,
    translations.watchListIsFull,
    showTextForIcon,
    iconSettings.text
  ]);

  return watchIcon;
};

const StyledAssetWatchIconButton = styled.button<{
  $actionDisabled?: boolean;
  $showTextForIcon?: boolean;
  color?: string;
  $fullList?: boolean;
}>`
  cursor: pointer;
  border: none;
  outline: none;
  min-width: ${(props) => (props.$showTextForIcon ? "140px" : "initial")};
  height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  opacity: ${(props) =>
    props.$actionDisabled || props.$fullList ? 0.5 : "initial"};
  pointer-events: ${(props) => (props.$actionDisabled ? "none" : "initial")};
  background-color: transparent;

  .ant-typography {
    color: ${colorTeal90};
  }

  .star-icon {
    width: 32px;

    &.error {
      color: ${colorStatusRed};
      opacity: 0.3;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }

  &[limitReached="true"] {
    opacity: 0.5;
  }
`;

export default WatchIcon;
