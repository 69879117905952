// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { NotificationContext } from "@pg/common/build/components/Notifications";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { saveAs } from "file-saver";
import { useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import { config } from "utils/AppConfig";

interface IUseExportModalOptions {
  modelId: string;
  handleExportModalClose: () => Promise<void>;
  exportConfiguration: boolean;
  exportCustomInputParameterConfiguration: boolean;
  exportCustomModelTranslations: boolean;
}

const useExportModal = ({
  modelId,
  handleExportModalClose,
  exportConfiguration,
  exportCustomInputParameterConfiguration,
  exportCustomModelTranslations
}: IUseExportModalOptions) => {
  const intl = useIntl();
  const { notifications } = useContext(NotificationContext);

  const exportConfigurations = useCallback(
    async () =>
      new Promise((resolve, reject) => {
        EndpointService.postBinary(
          UrlService.getApiUrl(config.api.exportModelConfiguration, [
            {
              name: "modelId",
              value: modelId
            }
          ]),
          (request, data: any) => {
            const fileName = request
              .getResponseHeader("Content-Disposition")
              .split("filename=")[1]
              .split(";")[0];
            const blob = new Blob([data], {
              type: "application/zip"
            });
            saveAs(blob, fileName);
            resolve(data);
          },
          (request) => {
            reject(new Error(request.responseJSON));
          },
          {
            ExportStandardModelConfiguration: exportConfiguration,
            ExportCustomInputParameterConfiguration:
              exportCustomInputParameterConfiguration,
            ExportCustomModelTranslations: exportCustomModelTranslations
          }
        );
      }),
    [
      modelId,
      exportConfiguration,
      exportCustomInputParameterConfiguration,
      exportCustomModelTranslations
    ]
  );

  const onExport = useCallback(async () => {
    try {
      handleExportModalClose();
      await exportConfigurations();
    } catch (e) {
      notifications.error({
        message: intl.formatMessage({
          id: "data_integration.model_config.export.failed",
          defaultMessage: "The files could not be exported."
        })
      });
    }
  }, [handleExportModalClose, exportConfigurations, notifications, intl]);

  return { onExport, intl };
};

export default useExportModal;
