// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux";
import { Actions } from "features/detailpage/features/actions/actions/ActionsActions";
import IWorkOrder from "features/detailpage/models/IWorkOrder";
import ActionsService from "features/detailpage/features/actions/services/ActionsService";

export interface IState {
  actions: {
    workOrders: Data<IWorkOrder[]>;
  };
}

export const defaultState: IState = {
  actions: {
    workOrders: new Data()
  }
};

const actionsReducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IWorkOrder[]>
) => {
  switch (action.type) {
    case Actions.DetailPage_Actions_SetWorkOrders:
      state = ActionsService.setWorkOrders(state, action.request, action.data);
      return state;
    default:
      return state;
  }
};

export default actionsReducer;
