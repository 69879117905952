// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const isTargetLink = (target: HTMLElement) =>
  target.tagName === "A" && target.classList.contains("link-label");

const hasParentLink = (target: HTMLElement) =>
  !!target.closest("a")?.classList.contains("link-label");

export default function isRowLinkClicked(e: EventTarget) {
  const target = e as HTMLElement;

  return isTargetLink(target) || hasParentLink(target);
}
