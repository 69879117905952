// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const getIsKinectricsTransformerMineralOilModel = (
  implementationId: string
) => {
  return (
    implementationId?.toLowerCase() ===
      AssetImplementationId.KinectricsTransformerMineralOilIEC ||
    implementationId?.toLowerCase() ===
      AssetImplementationId.KinectricsTransformerMineralOilIEEE
  );
};

export default getIsKinectricsTransformerMineralOilModel;
