// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { INotifications } from "@pg/common/build/components/Notifications";
import { Button } from "antd";
import Toggle, { ToggleModes } from "common/form/components/Toggle";
import UrlService from "core/data/services/UrlService";
import { saveAs } from "file-saver";
import $ from "jquery";
import { forEach } from "lodash";
import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import modalWindowStore from "store/common/modal/ModalWindowStore";
import { config } from "utils/AppConfig";
import "./XlsxDownloader.less";

interface IXlsxDownloaderProps {
  intl: IntlShape;
  notifications: INotifications;
  modalId: string;
  modelId: string;
}

interface IXlsxTemplate {
  FileName: string;
  DownloadFile: boolean;
}

interface IXlsxDownloaderState {
  templateItem: IXlsxTemplate[];
}

class XlsxDownloader extends React.Component<
  IXlsxDownloaderProps,
  IXlsxDownloaderState
> {
  constructor(props: IXlsxDownloaderProps) {
    super(props);

    this.state = {
      templateItem: []
    };
    this.getXlsxDownloaderComponent =
      this.getXlsxDownloaderComponent.bind(this);
    this.prepareTemplateItemsData = this.prepareTemplateItemsData.bind(this);
    this.changeDownloadState = this.changeDownloadState.bind(this);
    this.disableDownload = this.disableDownload.bind(this);
  }

  public render() {
    return this.getXlsxDownloaderComponent();
  }

  public componentDidMount() {
    this.prepareTemplateItemsData();
  }

  private getXlsxDownloaderComponent(): JSX.Element {
    let component = null as JSX.Element;
    const self = this;

    component = (
      <div className="xlsx-downloader">
        <div className="items">
          {this.state.templateItem.map((item: IXlsxTemplate, index: number) => {
            return (
              <div className="item" key={"xlsx-file-".concat(index.toString())}>
                <Toggle
                  defaultValue={item.DownloadFile}
                  label={{
                    defaultMessage: item.FileName,
                    id: "data_integration.xlsx_downloader.".concat(
                      item.FileName.toLowerCase()
                    )
                  }}
                  mode={ToggleModes.LabelOnRight}
                  onChange={() => {
                    self.changeDownloadState(index);
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="buttons">
          <Button
            type="primary"
            htmlType="submit"
            className="save"
            data-qa="save"
            onClick={function (
              e: React.MouseEvent<HTMLInputElement> &
                React.MouseEvent<HTMLAnchorElement>
            ) {
              forEach(
                self.state.templateItem.filter((file: IXlsxTemplate) => {
                  return file.DownloadFile;
                }) || [],
                (file: IXlsxTemplate) =>
                  self.downloadTemplate(file.FileName, self.props.modelId)
              );
              e.preventDefault();
              modalWindowStore.closeModal(self.props.modalId);
            }}
            disabled={this.disableDownload()}
          >
            {this.props.intl.formatMessage({
              id: "data_integration.xlsx_downloader.generate",
              defaultMessage: "Generate"
            })}
          </Button>
          <Button
            type="default"
            className="cancel"
            data-qa="cancel"
            onClick={function (
              e: React.MouseEvent<HTMLInputElement> &
                React.MouseEvent<HTMLAnchorElement>
            ) {
              modalWindowStore.closeModal(self.props.modalId);
            }}
          >
            {this.props.intl.formatMessage({
              id: "data_integration.xlsx_downloader.cancel",
              defaultMessage: "Cancel"
            })}
          </Button>
        </div>
      </div>
    );

    return component;
  }

  private downloadTemplate(templateName: string, modelName: string) {
    const { intl, notifications } = this.props;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.XlsxInspectionDataSourceUrl,
      [
        {
          name: "modelId",
          value: modelName
        },
        {
          name: "templateName",
          value: templateName
        }
      ]
    );

    $.ajax({
      url: url,
      dataType: "binary",
      type: "GET",
      processData: false,
      success: function (data: any) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, modelName + "_" + templateName + ".xlsx");
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        notifications.error({
          message: intl.formatMessage(
            {
              id: "data_integration.model_config.xlsx_downloader.download_error",
              defaultMessage: "Downloading {name} failed"
            },
            { name: templateName }
          )
        });
      }
    });
  }

  private prepareTemplateItemsData(): void {
    this.state.templateItem.push({
      DownloadFile: true,
      FileName: "Nameplate"
    });
    this.state.templateItem.push({
      DownloadFile: true,
      FileName: "Inspection"
    });
    this.setState(this.state);
  }

  private changeDownloadState(itemIndex: number): void {
    if (itemIndex !== null) {
      const newState = {
        ...this.state,
        templateItem: [...this.state.templateItem]
      };

      newState.templateItem[itemIndex] = {
        ...newState.templateItem[itemIndex],
        DownloadFile: !this.state.templateItem[itemIndex].DownloadFile
      };

      this.setState(newState);
    }
  }

  private disableDownload(): boolean {
    const collectionLength = this.state.templateItem.filter(
      (item: IXlsxTemplate) => {
        return item.DownloadFile;
      }
    ).length;

    if (collectionLength > 0) return false;
    return true;
  }
}

export default injectIntl(XlsxDownloader);
