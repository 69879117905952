import Icon from "@pg/common/build/components/Icon";
import { NotificationContext } from "@pg/common/build/components/Notifications";
import { PageModes } from "features/detailpage/models/PagesModes";
import { MouseEvent, useCallback, useContext } from "react";
import { FormattedMessage, IntlShape, MessageDescriptor } from "react-intl";

interface ITitlePanelProps {
  intl: IntlShape;
  pageMode: PageModes;
  allowToCollapse: boolean;
  expandPanel: () => void;
  collapsePanel: () => void;
}

const TitlePanel = ({
  pageMode,
  intl,
  allowToCollapse,
  expandPanel,
  collapsePanel
}: ITitlePanelProps) => {
  const { notifications } = useContext(NotificationContext);
  const showQuestionAboutUnsavedIssue = useCallback(() => {
    const message: MessageDescriptor = {
      defaultMessage:
        "Issue was not saved. Do you want to close the panel and discard changes?",
      id: "detail_page.sliding_panel.issues.unsaved_changes_question"
    };

    notifications.confirm({
      title: intl.formatMessage(message),
      okText: intl.formatMessage({
        id: "global.messages.yes",
        defaultMessage: "Yes"
      }),
      cancelText: intl.formatMessage({
        id: "global.messages.no",
        defaultMessage: "No"
      }),
      onOk: collapsePanel
    });
  }, [notifications, intl, collapsePanel]);

  const onPageModeLeft2Right2ExpandActionPlanClicked = useCallback(() => {
    if (allowToCollapse) {
      collapsePanel();
    } else {
      showQuestionAboutUnsavedIssue();
    }
  }, [allowToCollapse, collapsePanel, showQuestionAboutUnsavedIssue]);

  const onExpandActionPlanClicked = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      if (pageMode === PageModes.Left2Right2) {
        onPageModeLeft2Right2ExpandActionPlanClicked();
      } else if (pageMode === PageModes.Left3Right1) {
        expandPanel();
      }
    },
    [pageMode, expandPanel, onPageModeLeft2Right2ExpandActionPlanClicked]
  );

  return (
    <div className="right-title-panel">
      <Icon
        className={`
      expand
      ${pageMode === PageModes.Left3Right1 ? "left" : "right"}
    `}
        dataQa={pageMode === PageModes.Left3Right1 ? "left" : "right"}
        name="chevron_left"
        onClick={onExpandActionPlanClicked}
        size="lg"
      />
      <span className="title right-title">
        <FormattedMessage
          id="detail_page.widgets.issues_list.title"
          defaultMessage="Action Plan"
        />
      </span>
    </div>
  );
};

export default TitlePanel;
