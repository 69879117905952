import { PageModes } from "features/detailpage/models/PagesModes";
import { useCallback, useEffect, useState } from "react";

const usePageMode = (
  issueId: string,
  isParametersListWidgetVisible: boolean,
  rightPanelExpanded: boolean,
  collapseRightPanel: () => void
) => {
  const getInitialPageMode = useCallback(() => {
    if (isParametersListWidgetVisible) {
      return PageModes.Left4Right0;
    }

    return issueId ? PageModes.Left2Right2 : PageModes.Left3Right1;
  }, [isParametersListWidgetVisible, issueId]);

  const [pageMode, setPageMode] = useState<PageModes>(getInitialPageMode());
  const [isRightPanelExpanded, setIsRightPanelExpanded] =
    useState<boolean>(rightPanelExpanded);

  useEffect(() => {
    const newPageMode = isParametersListWidgetVisible
      ? PageModes.Left4Right0
      : PageModes.Left3Right1;

    if (newPageMode === PageModes.Left4Right0) {
      collapseRightPanel();
    }

    setPageMode(newPageMode);
  }, [collapseRightPanel, isParametersListWidgetVisible, setPageMode]);

  useEffect(() => {
    if (isRightPanelExpanded !== rightPanelExpanded) {
      setIsRightPanelExpanded(rightPanelExpanded);

      if (isParametersListWidgetVisible) {
        return;
      }

      if (rightPanelExpanded) {
        setPageMode(PageModes.Left2Right2);
      } else {
        setPageMode(PageModes.Left3Right1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightPanelExpanded]);

  return { pageMode, setPageMode };
};

export default usePageMode;
