// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IGasSettings, { GasName } from "../models/IGasSettings";

export const gasSettings: { [key in GasName]: IGasSettings } = {
  Acetylene: {
    unit: "ppm"
  },
  Ethylene: {
    unit: "ppm"
  },
  Ethane: {
    unit: "ppm"
  },
  Methane: {
    unit: "ppm"
  },
  Hydrogen: {
    unit: "ppm"
  },
  CarbonMonoxide: { unit: "ppm" },
  CarbonDioxide: {
    unit: "ppm"
  },
  Oxygen: {
    unit: "ppm"
  },
  Nitrogen: {
    unit: "ppm"
  },
  OxideOfCarbonRatioWithRegions: {
    name: "OxideOfCarbonRatio",
    reversedName: "OxideOfCarbonRatioReversed",
    useMinMaxRegions: true
  },
  OxideOfCarbonRatioWithThresholds: {
    name: "OxideOfCarbonRatio",
    reversedName: "OxideOfCarbonRatioReversed",
    useRegions: true
  },
  Hydran: {
    unit: "ppm"
  },
  TotalDissolvedCombustibleGases: {
    unit: "ppm"
  }
};

export default gasSettings;
