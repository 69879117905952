// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IHeaderParams } from "@apm/widgets/build/widgets/DataGrid";
import Icon from "@pg/common/build/components/Icon/components/Icon";
import { Input, InputRef, Tooltip, Typography } from "antd";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { colorGray60 } from "styles/ColorVariables";
import { spacingMedium, spacingXLarge } from "styles/StyleVariables";

const { Text } = Typography;
interface IModelParametersMappingColumnHeaderProps {
  className?: string;
  headerName: string;
  textInfo?: string;
}

type SortType = "asc" | "desc" | null;

type Props = IModelParametersMappingColumnHeaderProps & IHeaderParams;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: ${spacingMedium};
`;

const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${spacingMedium};
  margin-left: auto;
`;

const StyledInput = styled(Input)`
  max-width: 150px;
`;

const IconInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorGray60};
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SortingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: ${spacingXLarge};
`;

const ModelParametersMappingColumnHeader: FC<Props> = ({
  className,
  headerName,
  textInfo = "",
  enableSorting,
  setSort,
  column,
  api
}) => {
  const inputRef = useRef<InputRef>(null);
  const [sortType, setSortType] = useState<SortType>(null);
  const { formatMessage } = useIntl();

  const handleSortClick = useCallback(() => {
    if (sortType === "asc") setSort("desc");
    else if (sortType === "desc") setSort(null);
    else setSort("asc");
  }, [setSort, sortType]);

  const onSortChanged = useCallback(() => {
    setSortType(
      column.isSortAscending()
        ? "asc"
        : column.isSortDescending()
        ? "desc"
        : null
    );
  }, [column]);

  const onFilterChanged = useCallback(() => {
    if (inputRef.current?.input) {
      api
        .getColumnFilterInstance(column.getColDef().field)
        ?.then((filterInstance) => {
          if(filterInstance != null) {
            const filterModel = filterInstance.getModel();
            inputRef.current.input.value = filterModel?.filter ?? "";
          }
        });
    }
  }, [api, column]);

  const handleFilterChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      api
        .getColumnFilterInstance(column.getColDef().field)
        ?.then((filterInstance) => {
          if (filterInstance != null) {
            filterInstance.setModel({
              filterType: "text",
              type: "contains",
              filter: e.target.value
            });
            api.onFilterChanged();
          }
        });
    },
    [api, column]
  );

  useEffect(() => {
    column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, [column, onSortChanged]);

  useEffect(() => {
    column.addEventListener("filterChanged", onFilterChanged);
    onFilterChanged();
  }, [column, onFilterChanged]);

  return (
    <Wrapper className={className} onClick={handleSortClick}>
      <Text strong={true}>{headerName}</Text>
      <TooltipWrapper>
        <Tooltip title={textInfo}>
          <IconInfoWrapper>
            <Icon name="info" variant="outlined" size="sm" />
          </IconInfoWrapper>
        </Tooltip>
      </TooltipWrapper>
      {enableSorting && (
        <SortingWrapper>
          {sortType === "asc" && (
            <IconInfoWrapper>
              <Icon name="arrow_upward" size="sm" />
            </IconInfoWrapper>
          )}

          {sortType === "desc" && (
            <IconInfoWrapper>
              <Icon name="arrow_downward" size="sm" />
            </IconInfoWrapper>
          )}
        </SortingWrapper>
      )}
      <ControlWrapper
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        <StyledInput
          ref={inputRef}
          onChange={handleFilterChanged}
          allowClear={true}
          placeholder={formatMessage({
            id: "data_integration.model_config.model_parameters_mapping.model_param.search",
            defaultMessage: "Search"
          })}
        />
      </ControlWrapper>
    </Wrapper>
  );
};

export default ModelParametersMappingColumnHeader;
